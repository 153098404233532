// @flow
import React, { Component } from 'react';

const TOOLTIP_DELAY = 700;

type TooltipProps = {
    text?: string,
    children?: React.node,
}

type TooltipState = {
    isRollover: boolean,
}

class Tooltip extends Component<TooltipProps, TooltipState> {

    constructor(props: TooltipProps) {
        super(props)
        this.state = {
            isRollover: false,
        }
    }

    componentDidUpdate(prevProps: TooltipProps, prevState: TooltipState) {
        const { isRollover } = this.state;
        if ( isRollover !== prevState.isRollover ) {
            if ( isRollover ) {
                console.log('is rolling over');
            } else {
                console.log('is *not* rolling over');
            }
        }
    }

    componentWillUnmount() {
    }

    render() {
        const { children, text='' } = this.props;
        console.log(`Tooltip component TOOLTIP_DELAY: ${TOOLTIP_DELAY}`);
        return (
            <div>
                <span>Tooltip: {text}</span>
                {children}
            </div>
        )
    }
}

export default Tooltip;