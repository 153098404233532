// @flow
import React, {Component} from 'react'
import './CheckBox.sass'

type OwnProps = {
  id: string,
  name: string,
  value: string,
  onChange: (event: SyntheticEvent<EventTarget>, data: any) => void,
  checked?: boolean,
  defaultChecked?: boolean,
  readOnly?: boolean,
  children?: any,
}

type StateProps = {} & OwnProps

type Props = StateProps

class CheckBox extends Component<Props> {
  handleChange = (event: SyntheticEvent<EventTarget>) => {
    const {onChange} = this.props
    onChange && onChange(event, {})
  }

  inputElement: ?HTMLInputElement

  render() {
    const {id, name, value, size = 'normal', children} = this.props
    return (
      <div className="field">
        <input
          type="checkbox"
          className={`is-checkradio is-${size} has-background-color is-info`}
          id={id}
          name={name}
          value={value}
          ref={input => {
            this.inputElement = input
          }}
          onChange={this.handleChange}
        />
        <label htmlFor={id}>{children}</label>
      </div>
    )
  }
}

export default CheckBox
