// @flow
import React, {Component} from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

type TabViewProps = {
    id: string,
    activeIndex: number,
    style: any,
    className: string,
    onTabChange: (event: any) => void,
    children?: React.Node,
}

export class TabView extends Component<TabViewProps> {

    static defaultProps = {
        id: null,
        activeIndex: null,
        style: null,
        className: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.activeIndex !== null && nextProps.activeIndex !== prevState.activeIndex) {
            return {
                activeIndex: nextProps.activeIndex
            };
        }

        return null;
    }

    constructor(props: TabViewProps) {
        super(props)
        this.state = {
            activeIndex: this.props.activeIndex || 0
        };

        this.id = this.props.id || uniqueId();
    }

    onTabHeaderClick(event, tab, index) {
        if(!tab.props.disabled) {
            this.setState({
                activeIndex: index
            });

            if(this.props.onTabChange) {
                this.props.onTabChange({originalEvent: event, index: index});
            }
        }

        event.preventDefault();
    }

    renderContent() {
        let contents = React.Children.map(this.props.children, (tab, index) => {
            let selected = this.state.activeIndex === index;
            let className = classNames(tab.props.contentClassName, 'ui-tabview-panel ui-widget-content', {'ui-helper-hidden': !selected});
            let id = this.id + '_content_' + index;
            let ariaLabelledBy = this.id + '_header_' + index;

            return (
                <div id={id} aria-labelledby={ariaLabelledBy} aria-hidden={!selected} className={className} style={tab.props.contentStyle} role="tabpanel">
                    {selected && tab.props.children}
                </div>
            );
        })

        return (
            <div className="ui-tabview-panels">
                {contents}
            </div>
        );
    }

    renderNavigator() {
        let headers = React.Children.map(this.props.children, (tab, index) => {
            return this.renderTabHeader(tab, index);
        });

        return (
            <ul className="ui-tabview-nav ui-helper-reset ui-helper-clearfix ui-widget-header ui-corner-all" role="tablist">
                {headers}
            </ul>
        );
    }

    renderTabHeader(tab, index) {
        let selected = this.state.activeIndex === index;
        let className = classNames(tab.props.headerClassName, 'ui-state-default ui-corner-top', {'ui-tabview-selected ui-state-active': selected, 'ui-state-disabled': tab.props.disabled});
        let id = this.id + '_header_' + index;
        let ariaControls = this.id + '_content_' + index;

        return (
            <li className={className} role="presentation" style={tab.props.headerStyle}>
                <a role="tab" href={'#' + ariaControls} onClick={(e) => this.onTabHeaderClick(e, tab, index)} id={id} aria-controls={ariaControls} aria-selected={selected} >
                    {tab.props.leftIcon && <span className={classNames('ui-tabview-left-icon ', tab.props.leftIcon)}></span>}
                    <span className="ui-tabview-title">{tab.props.header}</span>
                    {tab.props.rightIcon && <span className={classNames('ui-tabview-right-icon ', tab.props.rightIcon)}></span>}
                </a>
            </li>
        );
    }

    render() {
        let className = classNames('ui-tabview ui-widget ui-widget-content ui-corner-all ui-tabview-top', this.props.className)
        let navigator = this.renderNavigator();
        let content = this.renderContent();

        return (
            <div id={this.props.id} className={className} style={this.props.style}>
                {navigator}
                {content}
            </div>
        );
    }
}

export default TabView