import './SideNavigation.scss';

import * as locales from 'moment/min/locales'; // eslint-disable-line

import {Connector, connect} from "react-redux";
import React, {Component} from 'react';

import { Button } from '../components/common/button';
import {Community} from '../common/icons';
import {DonateFood} from '../common/icons';
import {IconCommunityMap} from '../common/icons';
import {IconFoodBasket} from '../common/icons';
import {IconSettings} from '../common/icons';
import {ImpactReport} from '../common/icons';
import {Listing} from '../common/icons';
import {MessageCentre} from '../common/icons';
import {MyDonations} from '../common/icons';
import {MyProfile} from '../common/icons';
import { NavLink } from 'react-router-dom';
import ProfilePicture from '../components/ProfilePicture';
import WithProfileType from '../common/components/withProfileType';
import WithRole from "./withRole";
import { getIsAuthenticated } from 'redux-simple-auth';
import { getSessionData } from '../modules/session/selectors';
import moment from 'moment';
import styled  from 'styled-components';
import { withRouter } from 'react-router-dom';

export const SideNavigationContainer = styled.div`
  display: flex;
  font-family OpenSansRegular,'Helvetica Neue',Helvetica,Arial,sans-serif;
  flex-direction: column;
  background-color: #F0F0F0;
  border-right: 1px solid #8D8C8D;
  height: 100%;
`;

export const SideNavigationProfile = styled.div`
  display: flex;
  align-items: center;
`;

export const SideNavigationHeader = styled.div`
  padding: 20px;
`;

export const SideNavigationSlogan = styled.div`
  font-size: 20px;
  color: #A9A9A9;
`;

export const SideNavigationCallout = styled.div`
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  padding: 20px;
`;

// helper class
export const SideNavigationMenuButton = ({to, icon: Icon, children, ...rest}) => {
    return (
        <NavLink exact className="button is-multiline is-navigation" to={to}>
            <React.Fragment>
                { Icon ? <div className="navigation_icon"><Icon /></div> : null }
                <span>{children}</span>
            </React.Fragment>
        </NavLink>
    );
};

export const SideNavigationMenu = styled.div`
  display: flex;
  flex-direction: column;
    
  button {
      padding-left: 20px;
      border-radius: 0;
  }
`;

export const ProfileCallout = styled.span`
  font-weight: normal;
  font-size: 20px;
  padding: 10px;
`;

export const ProfileName = styled.span`
  font-weight: bold;
  font-size: 20px;
  word-break: break-word;
`;

type OwnProps = {
    text: string,
};

type StateProps = {
    session: any,
} & OwnProps;

type DispatchProps = {
};

type Props = StateProps & DispatchProps;

class SideNavigation extends Component {
    onContactClick = (event) => {
        event.preventDefault();
        window.open('mailto:info@planzheroes.org?subject=Feedback');
    };

    onAvatarClick = (event) => {
        const { history, isAuthenticated } = this.props;
        if (isAuthenticated) {
            history.push('/my/profile');
        }       
    }

    props: Props;

    componentDidUpdate(prevProps) {
        if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
            this.setApplicationLocale()
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated) {
            this.setApplicationLocale()
        }
    }

    setApplicationLocale() {
        // Forcefully set the moment locale to gB
        moment.locale('en-gb')
    }

    render() {
        const { session } = this.props;
        const primaryProfileAddress = session.profile && session.profile.addresses && session.profile.addresses[0];
        const profileName = primaryProfileAddress && primaryProfileAddress.firstName;
        const profileImageUrl = session.profile && session.profile.image && session.profile.image.url;
        return (
            <SideNavigationContainer className="sidenavigation">
                <SideNavigationHeader>
                    <SideNavigationProfile>
                        <ProfilePicture style={{borderRadius: '50%'}} size={{width: 120}} mode="small" imageUrl={profileImageUrl} onClick={this.onAvatarClick} />
                        <ProfileCallout className="is-size-5">Hi, <ProfileName>{profileName}</ProfileName></ProfileCallout>
                    </SideNavigationProfile>
                    <SideNavigationSlogan>
                        <WithProfileType type="business">
                            <p className="is-size-5" style={{marginTop: '1rem', marginBottom: '1rem'}}>Thanks for donating your surplus food!</p>
                        </WithProfileType>
                        <WithProfileType type={["charity", "volunteer", "transporter"]}>
                            <p className="is-size-5" style={{marginTop: '1rem', marginBottom: '1rem'}}>Thanks for using surplus food!</p>
                        </WithProfileType>
                        <p className="is-size-5">You're our hero.</p>
                    </SideNavigationSlogan>
                </SideNavigationHeader>
                <SideNavigationMenu>
                    <WithProfileType type="business">
                        <SideNavigationMenuButton to="/donations/create" icon={DonateFood}>Donate food</SideNavigationMenuButton>
                        <SideNavigationMenuButton to="/donations/created" icon={MyDonations}>My Donations</SideNavigationMenuButton>
                        <SideNavigationMenuButton to="/dashboard" icon={ImpactReport}>Impact report</SideNavigationMenuButton>
                    </WithProfileType>
                    <WithProfileType type="charity">
                        <SideNavigationMenuButton to="/donations/available" icon={Listing}>Food available</SideNavigationMenuButton>
                        <SideNavigationMenuButton to="/donations/claimed" icon={IconFoodBasket}>Food claimed</SideNavigationMenuButton>
                        <SideNavigationMenuButton to="/donations/created" icon={MyDonations}>My donations</SideNavigationMenuButton>
                        <SideNavigationMenuButton to="/dashboard" icon={ImpactReport}>Impact report</SideNavigationMenuButton>
                    </WithProfileType>
                    <WithProfileType type={["volunteer", "transporter"]}>
                        <SideNavigationMenuButton to="/donations/claimed" icon={ImpactReport}>Food being donated</SideNavigationMenuButton>
                    </WithProfileType>
                    <SideNavigationMenuButton to="/my/community" icon={IconCommunityMap}>My Community</SideNavigationMenuButton>
                    <SideNavigationMenuButton to="/map" icon={Community}>Community Map</SideNavigationMenuButton>
                    <WithRole type="admin">
                        <SideNavigationMenuButton to="/messages" icon={MessageCentre}>Message centre</SideNavigationMenuButton>
                    </WithRole>
                    <SideNavigationMenuButton to="/my/profile" icon={MyProfile}>My Profile</SideNavigationMenuButton>
                    <SideNavigationMenuButton to="/my/settings" icon={IconSettings}>Settings</SideNavigationMenuButton>
                    <WithProfileType type="charity">
                        <SideNavigationMenuButton to="/donations/create" icon={DonateFood}>Donate your surplus food</SideNavigationMenuButton>
                    </WithProfileType>
                </SideNavigationMenu>
                <SideNavigationCallout>
                    <Button className="is-orange" onClick={this.onContactClick}>Need help? Contact us</Button>
                </SideNavigationCallout>
            </SideNavigationContainer>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    session: getSessionData(state),
    isAuthenticated: getIsAuthenticated(state),
    ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps, null, { pure: false });

export default withRouter(connector(SideNavigation));
