import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    byId: [],
    entities: {},
    errors: {},
    actionStatus: {},
    fetchStatus: {},
}

export default function profileReducer(state = initialState, action) {
    const payload = action.payload;

    switch (action.type) {
        case actionTypes.PROFILE_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };
        case actionTypes.PROFILE_RECEIVED:
            return {
                ...state,
                byId: [...state.byId, payload.id],
                entities: {
                    ...state.entities,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                isLoading: false,
            };
        case actionTypes.PROFILE_REJECTED:
            return {
                ...state,
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'failed',
                },
                isLoading: false,
            };

        case actionTypes.CREATE_PROFILE_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };
        case actionTypes.CREATE_PROFILE_RECEIVED:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                isLoading: false,
            };
        case actionTypes.CREATE_PROFILE_REJECTED:
            return {
                ...state,
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'failed',
                },
                isLoading: false,
            };

        case actionTypes.CURRENT_PROFILE_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };
        case actionTypes.CURRENT_PROFILE_RECEIVED:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                isLoading: false,
            };
        case actionTypes.CURRENT_PROFILE_REJECTED:
            return {
                ...state,
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'failed',
                },
                isLoading: false,
            };

        case actionTypes.PROFILE_IMAGE_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };
        case actionTypes.PROFILE_IMAGE_RECEIVED:
            return {
                ...state,
                entities: {
                    ...state.entities,
                    [action.meta.id]: {
                        ...state.entities[action.meta.id],
                        image: payload,
                    },
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                isLoading: false,
            };
        case actionTypes.PROFILE_IMAGE_REJECTED:
            return {
                ...state,
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },
                isLoading: false,
            };

        case actionTypes.INIT:
            return {
                ...state,
                actionStatus: {
                    ...state.actionStatus,
                    [action.meta.id]: 'idle',
                },
                isLoading: false,
            };

        case actionTypes.ADD_FRIENDSHIP_RECEIVED:
            return {
                ...state
            }
        case actionTypes.REMOVE_FRIENDSHIP_RECEIVED:
            return {
                ...state
            }

        case actionTypes.UPDATE_PROFILE_LOADING:
            return {
                ...state,
                actionStatus: {
                    ...state.actionStatus,
                    [action.meta.id]: 'saving',
                },
                isLoading: true,
            };
        case actionTypes.UPDATE_PROFILE_RECEIVED:
            return {
                ...state,
                actionStatus: {
                    ...state.actionStatus,
                    [action.meta.id]: 'saved',
                },
                isLoading: false,
            };
        case actionTypes.UPDATE_PROFILE_REJECTED:
            return {
                ...state,
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },
                isLoading: false,
            };
        default:
            return state;
    }
}