import has from 'lodash/has'

export class ServerValidationError extends Error {

    constructor(message = 'Validation error', statusCode = 412, errors) {
        super(message)
        this.name = 'ServerValidationError';
        this.errors = [];
        this.statusCode = statusCode;

        if (errors) {
            this.errors = errors;
        }

        if (Error.captureStackTrace) Error.captureStackTrace(this, ServerValidationError);
    }

    isError = (err) => {
        return err && err.name === 'ServerValidationError';
    }
}

export const isValidationRelatedResponse = status => {
    return (status === 400 ||  status === 409 || (status >= 412 && status <= 413) || status === 421)
}

export const processValidationErrors = (response) => {
    return new Promise((resolve, reject) => {
        if (!isValidationRelatedResponse(response.status)) {
            if (!response.ok) {
                throw Error(response.statusText)
            }

            response.json().then(json => { 
                resolve(json) 
            }).catch(err => {
                reject(err)
            })
            return
        }

        response.json().then(responseData => {
            const responseValidation = responseData.validation ? responseData.validation : null
            if (has(responseData, 'validation.errors')) {
                const { errors:validationErrors = [] } = responseValidation // eslint-disable-line
                reject(new ServerValidationError(responseData.message, responseData.statusCode || 412, validationErrors));    
            } else {
                reject(new ServerValidationError(responseData.message, responseData.statusCode || 412, []));    
            }
        }).catch(error => {
            reject(error);
        })
    })
};