import { createSelector } from 'reselect';
import {
    NAME,
    TRANSPORTOPTIONS,
    DONATIONFOODTYPES,
    DONATIONSIZES,
    COLLECTIONTIMES,
    VOLUNTEERROLES,
    FOODTYPES,
} from './constants';


export const getAll = state => {
    return state[NAME];
}

export const isLoading = createSelector(
    [getAll],
    state => state.loading
        || state.foodTypesLoading
        || state.transportOptionsLoading
        || state.donationSizesLoading
        || state.donationFoodTypesLoading
        || state.collectionTimesLoading
        || state.volunteerRolesLoading,
);

export const isReady = createSelector(
    [getAll],
    state => state.ready
        && state.foodTypesReady
        && state.transportOptionsReady
        && state.donationSizesReady
        && state.collectionTimesReady
);

/**
 * Get list of possible user statuses for work order operations
 */
export const foodTypesSet = createSelector(
    [getAll],
    lookups => lookups[FOODTYPES],
);

/**
 * Get list of possible user statuses for work order operations
 */
export const transportOptionsSet = createSelector(
    [getAll],
    lookups => lookups[TRANSPORTOPTIONS],
);

/**
 * Get list of possible user statuses for work order operations
 */
export const collectionTimesSet = createSelector(
    [getAll],
    lookups => lookups[COLLECTIONTIMES],
);

/**
 * Get list of possible user statuses for work order operations
 */
export const donationSizesSet = createSelector(
    [getAll],
    lookups => lookups[DONATIONSIZES],
);

/**
 * Get list of possible user statuses for work order operations
 */
export const donationFoodTypesSet = createSelector(
    [getAll],
    lookups => lookups[DONATIONFOODTYPES],
);

/**
 * Get list of possible user statuses for work order operations
 */
export const volunteerRolesSet = createSelector(
    [getAll],
    lookups => lookups[VOLUNTEERROLES],
);

export default {
    isReady,
    isLoading,
    foodTypesSet,
    transportOptionsSet,
    collectionTimesSet,
    volunteerRolesSet,
    donationSizesSet,
    donationFoodTypesSet
}