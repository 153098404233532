// @flow
import { PrimaryButton, Button } from './button';
import Input from './input';
import CheckBox from './CheckBox';
import TabView from './TabView';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import Icon from './Tooltip';

import {
    Title,
    SubTitle,
    Paragraph,
    PageWrapper,
    WrapperContent,
    MainContentWrapper,
    Wrapper,
} from './global-components';

export {
    Title,
    SubTitle,
    Paragraph,
    PageWrapper,
    WrapperContent,
    MainContentWrapper,
    Wrapper,
    PrimaryButton,
    Button,
    Input,
    CheckBox,
    Icon,
    TabView,
    Spinner,
    Tooltip,
};