import React, { Component } from 'react';
import { Connector, connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getIsAuthenticated, getIsRestored, invalidateSession } from 'redux-simple-auth';
import { getSessionData } from '../../modules/session/selectors';

import styled  from 'styled-components';
import { headerHeight, navigationBarBg } from "../common/variables";
import { Button } from '../common/button';
import Logo from './Logo';
import WithProfileType from '../../common/components/withProfileType';
import { REACT_APP_DOMAIN } from '../../app-constants';

import './Header.scss';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${navigationBarBg};
  min-height: ${headerHeight};
  color: white;

  @media (max-width: 320px) {
    min-height: 60px;
  }
`;

const HeaderText = styled.div`
  flex: 1;
  font-family: OpenSansRegular,'Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 24px;
  font-style: normal;
  margin-left: 20px;

  @media (max-width: 320px) {
      font-size: 14px;
  }
`;

// const HeaderButtons = styled.div`
//   display: inline-flex;
//   margin-left: 10px;
//   margin-right: 10px;
  
//   * {
//     margin-right: 10px;
    
//     &:last-child {
//       margin-right: 0;
//     }
//   }

//   @media (max-width: 320px) {
//     display: none;
//   }
// `;

type OwnProps = {
    isAuthenticated: boolean,
    isSessionRestored: boolean,
    user: any,
};

type StateProps = {
    isAuthenticated: boolean,
} & OwnProps;

type DispatchProps = {
    logout: () => void,
};

type Props = StateProps & DispatchProps;

class Header extends Component<Props> {

    props: Props;

    createDonation = (event) => {
        event.preventDefault()
        const { history } = this.props;
        history.push('/donations/create');
    };

    onLogoClick = (event) => {
        event.preventDefault()
        const { history, isAuthenticated } = this.props;
        if (isAuthenticated) {
            history.push('/dashboard');
        } else {
            history.push('/login');
        }
    }

    onSignupClicked = (event) => {
        event.preventDefault()
        this.props.history.push('/registration')
    }
    onLoginClicked = (event) => {
        event.preventDefault()
        this.props.history.push('/login')
    }
    onLogoutClicked = (event) => {
        event.preventDefault()
        this.props.history.push('/login')
        this.props.logout()
    }
    navigateToContentSite = (event) => {
        event.preventDefault()
        window.open(`https://${REACT_APP_DOMAIN}`, '_blank')
    }

    render() {
        const { isAuthenticated, session } = this.props;

        return (
            <HeaderContainer className="header">
                <a className="header_logo" href="/" onClick={this.onLogoClick} title="Go to Home"><Logo /></a>
                <HeaderText>Getting great surplus food to good causes</HeaderText>

                { isAuthenticated && (
                     <Button.Group style={{marginRight: '0.5rem'}}>
                         <Button outlined inverted color="black" onClick={this.navigateToContentSite}>Visit our website</Button>
                         {session.profile && session.profile.hasBranchProfiles === false ? (
                             <WithProfileType type={["charity", "business"]}>
                                 <Button className="is-secondary" onClick={this.createDonation}>+ Donate food</Button>
                             </WithProfileType>
                         ) : null}
                         <Button outlined className="is-secondary" onClick={this.onLogoutClicked}>Logout</Button> 
                     </Button.Group>
                 )}

                { !isAuthenticated && (
                    <Button.Group style={{marginRight: '0.5rem'}}>
                         <Button outlined inverted color="black" onClick={this.navigateToContentSite}>Visit our website</Button>
                         <Button className="is-primary" onClick={this.onSignupClicked}>Sign up</Button> 
                         <Button className="is-secondary" onClick={this.onLoginClicked}>Log in</Button> 
                     </Button.Group>
                )}
            </HeaderContainer>
        );
    }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
    isSessionRestored: getIsRestored(state),
    isAuthenticated: getIsAuthenticated(state),
    session: getSessionData(state),
    user: state.user,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
        dispatch,
        logout: () => {
            localStorage.removeItem('auth')
            sessionStorage.removeItem('auth')
            dispatch(invalidateSession())
        }
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(Header));
