export const REACT_APP_DOMAIN = window.env.REACT_APP_DOMAIN || process.env.REACT_APP_DOMAIN || ''
export const REACT_APP_GATEWAY_SERVER_URL = window.env.REACT_APP_GATEWAY_SERVER_URL || process.env.REACT_APP_GATEWAY_SERVER_URL || ''
export const REACT_APP_SENTRY_URI = window.env.REACT_APP_SENTRY_URI || process.env.REACT_APP_SENTRY_URI || ''
export const REACT_APP_SECRET_CODE = window.env.REACT_APP_SECRET_CODE || process.env.REACT_APP_SECRET_CODE || ''
export const REACT_APP_APP_TITLE = window.env.REACT_APP_APP_TITLE || process.env.REACT_APP_APP_TITLE || ''
export const REACT_APP_GA_TRACK_ID = window.env.REACT_APP_GA_TRACK_ID || process.env.REACT_APP_GA_TRACK_ID || ''
export const REACT_APP_MAXIMUM_FILE_SIZE = window.env.REACT_APP_MAXIMUM_FILE_SIZE || process.env.REACT_APP_MAXIMUM_FILE_SIZE || ''
export const REACT_APP_ADDRESSNOW_API_KEY = window.env.REACT_APP_ADDRESSNOW_API_KEY || process.env.REACT_APP_ADDRESSNOW_API_KEY || ''
export const REACT_APP_GOOGLEMAPS_API_KEY = window.env.REACT_APP_GOOGLEMAPS_API_KEY || process.env.REACT_APP_GOOGLEMAPS_API_KEY || ''
export const REACT_APP_CONDITIONS_URL = window.env.REACT_APP_CONDITIONS_URL || process.env.REACT_APP_CONDITIONS_URL || ''
export const REACT_APP_FEATURE_MEMBERSHIPS = window.env.REACT_APP_FEATURE_MEMBERSHIPS || process.env.REACT_APP_FEATURE_MEMBERSHIPS || ''
export const REACT_APP_SENTRY_ENVIRONMENT = window.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.REACT_APP_SENTRY_ENVIRONMENT || ''

export default {
    REACT_APP_DOMAIN,
    REACT_APP_GATEWAY_SERVER_URL,
    REACT_APP_SENTRY_URI,
    REACT_APP_SECRET_CODE,
    REACT_APP_APP_TITLE,
    REACT_APP_GA_TRACK_ID,
    REACT_APP_MAXIMUM_FILE_SIZE,
    REACT_APP_ADDRESSNOW_API_KEY,
    REACT_APP_GOOGLEMAPS_API_KEY,
    REACT_APP_CONDITIONS_URL,
    REACT_APP_FEATURE_MEMBERSHIPS,
    REACT_APP_SENTRY_ENVIRONMENT,
}
