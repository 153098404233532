import { createGlobalStyle } from 'styled-components';
import BackgroundPattern from './assets/bg-pattern.png';
import breeSerifWOFF from './assets/fonts/breeserif-regular-webfont.woff';
import breeSerifTTF from './assets/fonts/breeserif-regular-webfont.ttf';
import breeSerifSVG from './assets/fonts/breeserif-regular-webfont.svg';
import openSansWOFF from './assets/fonts/opensans-regular-webfont.woff';
import openSansTTF from './assets/fonts/opensans-regular-webfont.ttf';
import openSansSVG from './assets/fonts/opensans-regular-webfont.svg';
import openSansBoldWOFF from './assets/fonts/opensans-semibold-webfont.woff';
import openSansBoldTTF from './assets/fonts/opensans-semibold-webfont.ttf';
import openSansBoldSVG from './assets/fonts/opensans-semibold-webfont.svg';

/* eslint no-unused-expressions: 0 */
export const GlobalStyles = createGlobalStyle`
  /* Border-Box */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    margin: 0;
  }

  // Font: Bree Serif Regular
  @font-face {
    font-display: auto;
    font-family: 'BreeSerifRegular';
    src: url(${breeSerifWOFF}) format('woff'),
    url(${breeSerifTTF}) format('truetype'),
    url(${breeSerifSVG}) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  // Font: Bree Serif Bold
  @font-face {
    font-display: auto;
    font-family: 'BreeSerifBold';
    src: url(${breeSerifWOFF}) format('woff'),
    url(${breeSerifTTF}) format('truetype'),
    url(${breeSerifSVG}) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  // Font: Open Sans Regular
  @font-face {
    font-display: auto;
    font-family: 'OpenSansRegular';
    src: url(${openSansWOFF}) format('woff'),
    url(${openSansTTF}) format('truetype'),
    url(${openSansSVG}) format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  // Font: Open Sans Semibold
  @font-face {
    font-display: auto;
    font-family: 'OpenSansBold';
    src: url(${openSansBoldWOFF}) format('woff'),
    url(${openSansBoldTTF}) format('truetype'),
    url(${openSansBoldSVG}) format('svg');
    font-weight: bold;
    font-style: normal;
  }  

  body {
    font-family: OpenSansRegular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: OpenSansRegular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    background-image: url(${BackgroundPattern});
    font-family OpenSansRegular, 'Helvetica Neue', Helvetica,Arial, sans-serif;
  }

  p,
  label {
    font-family: OpenSansRegular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }

  strong {
    font-family: OpenSansBold, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
`;

export default GlobalStyles
