import React from 'react';

export const PageLayout = ({ children, ...rest }) => {
    return (
        <div className="page" style={{ height: '100%' }}>
            <div className="main-content" style={{ height: '100%' }}>
                {children}
            </div>
        </div>
    );
};
