import React, { Component } from 'react';
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;

const checkVersionChange = (latest, current) => {
    //convert into arrays with major, minor, patch versions stored from 0-2 respectively
    const versionsA = latest.split(/\./g);
    const versionsB = current.split(/\./g);
    console.log("Latest: " + latest);
    console.log("Current: " + current);

    while (versionsA.length || versionsB.length) {
        //pop first number off each array and store value for comparison
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        if (a === b) continue;
        if (a < b) return false;
        //if b isNaN version has not been set so probably worth cachebusting
        return a > b || isNaN(b);
    }
    return false;
}

/**
 * This acts as a higher order component which checks the package.json version with a generated
 * build version (created by generate-build-version.js) saved in the public folder
 */
class CacheBuster extends Component {

    componentDidMount() {
        fetch('/meta.json', {cache: "no-store"})
            .then(response => response.json())
            .then(meta => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;
                const change = checkVersionChange(latestVersion, currentVersion);
                if (change) {
                    console.log("Versions are different");
                    window.location.reload(true);
                } else {
                    console.log("Versions are the same");
                }
            })
            .catch(error => console.log("Error fetching meta.json: " + error));
    }

    render() {
        return this.props.children;
    }
}

export default CacheBuster;