import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { getIsAuthenticated, getSessionData } from 'redux-simple-auth'
import { DashboardLayout } from '../containers/DashboardLayout'
import { HeadquarterDashboardLayout } from '../containers/HeadquarterDashboardLayout'

const DashboardRoute = (props) => {
    const { component: Component, isAuthenticated, authData, ...rest } = props;
    const AuthLayout = authData && authData.hasBranchProfiles ? HeadquarterDashboardLayout : DashboardLayout
    return (
        <Route
            {...rest}
            render={matchProps => {
                return isAuthenticated ? (
                    <AuthLayout>
                        <Component {...props} />
                    </AuthLayout>
                ) : (
                    <Redirect
                        to={{ pathname: '/login', state: { from: matchProps.location.pathname } }}
                    />
                );
            }}
        />
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: getIsAuthenticated(state),
        authData: getSessionData(state),
    };
};

export default connect(mapStateToProps)(DashboardRoute);
