export const INIT = 'profiles/INIT';

export const CREATE_PROFILE_LOADING = 'profiles/CREATE_PROFILE_LOADING';
export const CREATE_PROFILE_RECEIVED = 'profiles/CREATE_PROFILE_RECEIVED';
export const CREATE_PROFILE_REJECTED = 'profiles/CREATE_PROFILE_REJECTED';

export const CURRENT_PROFILE_LOADING = 'profiles/CURRENT_PROFILE_LOADING';
export const CURRENT_PROFILE_RECEIVED = 'profiles/CURRENT_PROFILE_RECEIVED';
export const CURRENT_PROFILE_REJECTED = 'profiles/CURRENT_PROFILE_REJECTED';

export const UPDATE_PROFILE_LOADING = 'profiles/UPDATE_PROFILE_LOADING';
export const UPDATE_PROFILE_RECEIVED = 'profiles/UPDATE_PROFILE_RECEIVED';
export const UPDATE_PROFILE_REJECTED = 'profiles/UPDATE_PROFILE_REJECTED';

export const PROFILE_LOADING = 'profiles/PROFILE_LOADING';
export const PROFILE_RECEIVED = 'profiles/PROFILE_RECEIVED';
export const PROFILE_REJECTED = 'profiles/PROFILE_REJECTED';

export const PROFILE_IMAGE_LOADING = 'profiles/PROFILE_IMAGE_LOADING';
export const PROFILE_IMAGE_RECEIVED = 'profiles/PROFILE_IMAGE_RECEIVED';
export const PROFILE_IMAGE_REJECTED = 'profiles/PROFILE_IMAGE_REJECTED';

export const ADD_FRIENDSHIP_LOADING = 'profiles/ADD_FRIENDSHIP_LOADING';
export const ADD_FRIENDSHIP_RECEIVED = 'profiles/ADD_FRIENDSHIP_RECEIVED';
export const ADD_FRIENDSHIP_REJECTED = 'profiles/ADD_FRIENDSHIP_REJECTED';

export const REMOVE_FRIENDSHIP_LOADING = 'profiles/REMOVE_FRIENDSHIP_LOADING';
export const REMOVE_FRIENDSHIP_RECEIVED = 'profiles/REMOVE_FRIENDSHIP_RECEIVED';
export const REMOVE_FRIENDSHIP_REJECTED = 'profiles/REMOVE_FRIENDSHIP_REJECTED';
