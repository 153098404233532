import Modal from 'react-modal';

Modal.defaultStyles.overlay.display = 'flex';
Modal.defaultStyles.overlay.alignItems = 'center';
Modal.defaultStyles.overlay.justifyContent = 'center';
Modal.defaultStyles.overlay.backgroundColor = 'rgb(0, 0, 0, 0.5)';
Modal.defaultStyles.overlay.zIndex = 1000;
Modal.defaultStyles.content.top = '';
Modal.defaultStyles.content.left = '';
Modal.defaultStyles.content.right = '';
Modal.defaultStyles.content.bottom = '';
Modal.defaultStyles.content.width = 'auto';
Modal.defaultStyles.content.height = 'auto';
Modal.defaultStyles.content.maxWidth = '600px';