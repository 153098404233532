import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';

export class ErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        this.notifyException(error, errorInfo)
    }

    notifyException(error, errorInfo) {
        try {
            Sentry.captureException(error, { extra: errorInfo });
        } catch(error) {
            // noop
        }
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                <h2>Something went wrong.</h2>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                </details>
                </div>
            );
        }
            
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary