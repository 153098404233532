export const INIT = 'donations/INIT';

export const DONATION_LOADING = 'donations/DONATION_LOADING';
export const DONATION_RECEIVED = 'donations/DONATION_RECEIVED';
export const DONATION_REJECTED = 'donations/DONATION_REJECTED';

export const DONATIONS_LOADING = 'donations/DONATIONS_LOADING';
export const DONATIONS_RECEIVED = 'donations/DONATIONS_RECEIVED';
export const DONATIONS_REJECTED = 'donations/DONATIONS_REJECTED';

export const CREATE_DONATION_LOADING = 'donations/CREATE_DONATION_LOADING';
export const CREATE_DONATION_RECEIVED = 'donations/CREATE_DONATION_RECEIVED';
export const CREATE_DONATION_REJECTED = 'donations/CREATE_DONATION_REJECTED';

export const UPDATE_DONATION_LOADING = 'donations/UPDATE_DONATION_LOADING';
export const UPDATE_DONATION_RECEIVED = 'donations/UPDATE_DONATION_RECEIVED';
export const UPDATE_DONATION_REJECTED = 'donations/UPDATE_DONATION_REJECTED';

export const UPDATE_DONATION_DELIVERY_STATUS_LOADING = 'donations/UPDATE_DONATION_DELIVERY_STATUS_LOADING';
export const UPDATE_DONATION_DELIVERY_STATUS_RECEIVED = 'donations/UPDATE_DONATION_DELIVERY_STATUS_RECEIVED';
export const UPDATE_DONATION_DELIVERY_STATUS_REJECTED = 'donations/UPDATE_DONATION_DELIVERY_STATUS_REJECTED';

export const UPDATE_REVIEW_DONATION_ITEM_LOADING = 'donations/UPDATE_REVIEW_DONATION_ITEM_LOADING';
export const UPDATE_REVIEW_DONATION_ITEM_RECEIVED = 'donations/UPDATE_REVIEW_DONATION_ITEM_RECEIVED';
export const UPDATE_REVIEW_DONATION_ITEM_REJECTED = 'donations/UPDATE_REVIEW_DONATION_ITEM_REJECTED';

export const UPDATE_DELIVERY_REQUEST_LOADING = 'donations/UPDATE_DELIVERY_REQUEST_LOADING';
export const UPDATE_DELIVERY_REQUEST_RECEIVED = 'donations/UPDATE_DELIVERY_REQUEST_RECEIVED';
export const UPDATE_DELIVERY_REQUEST_REJECTED = 'donations/UPDATE_DELIVERY_REQUEST_REJECTED';

export const DELETE_DONATION_LOADING = 'donations/DELETE_DONATION_LOADING';
export const DELETE_DONATION_RECEIVED = 'donations/DELETE_DONATION_RECEIVED';
export const DELETE_DONATION_REJECTED = 'donations/DELETE_DONATION_REJECTED';

export const CLAIM_DONATION_LOADING = 'donations/CLAIM_DONATION_LOADING';
export const CLAIM_DONATION_RECEIVED = 'donations/CLAIM_DONATION_RECEIVED';
export const CLAIM_DONATION_REJECTED = 'donations/CLAIM_DONATION_REJECTED';

export const DONATION_IMAGE_LOADING = 'donations/DONATION_IMAGE_LOADING';
export const DONATION_IMAGE_RECEIVED = 'donations/DONATION_IMAGE_RECEIVED';
export const DONATION_IMAGE_REJECTED = 'donations/DONATION_IMAGE_REJECTED';

export const DONATION_PERIODS_LOADING = 'donations/DONATION_PERIODS_LOADING';
export const DONATION_PERIODS_RECEIVED = 'donations/DONATION_PERIODS_RECEIVED';
export const DONATION_PERIODS_REJECTED = 'donations/DONATION_PERIODS_REJECTED';

export const STATISTICS_LOADING = 'donations/STATISTICS_LOADING';
export const STATISTICS_RECEIVED = 'donations/STATISTICS_RECEIVED';
export const STATISTICS_REJECTED = 'donations/STATISTICS_REJECTED';

export const EXPORT_DONATION_REPORT_LOADING = 'donations/EXPORT_DONATION_REPORT_LOADING';
export const EXPORT_DONATION_REPORT_RECEIVED = 'donations/EXPORT_DONATION_REPORT_RECEIVED';
export const EXPORT_DONATION_REPORT_REJECTED = 'donations/EXPORT_DONATION_REPORT_REJECTED';

export const EXPORT_SUMMARY_DONATION_REPORT_LOADING = 'donations/EXPORT_SUMMARY_DONATION_REPORT_LOADING';
export const EXPORT_SUMMARY_DONATION_REPORT_RECEIVED = 'donations/EXPORT_SUMMARY_DONATION_REPORT_RECEIVED';
export const EXPORT_SUMMARY_DONATION_REPORT_REJECTED = 'donations/EXPORT_SUMMARY_DONATION_REPORT_REJECTED';
