// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import {
    credentialsAuthenticator,
    bearerAuthorizer,
    authenticatorStorage,
} from './utils/Authentication';
import {
    createAuthMiddleware,
} from 'redux-simple-auth';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';

export const history = createBrowserHistory();
if (history.location && history.location.state && history.location.state.from) {
    const state = { ...history.location.state };
    delete state.from;
    history.replace({ ...history.location, state });
}

const authMiddleware = createAuthMiddleware({
    storage: authenticatorStorage,
    authorize: bearerAuthorizer,
    authenticator: credentialsAuthenticator
});

const initialState = {};
const enhancers = [];
const middleware = [thunk, authMiddleware];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer, initialState, composedEnhancers);
