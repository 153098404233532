import * as Sentry from '@sentry/browser';

import 'url-search-params-polyfill';
import {
    createLocalStorageStore,
    createCredentialsAuthenticator,
} from 'redux-simple-auth';
import { has, get as getValue } from 'lodash';
import { REACT_APP_GATEWAY_SERVER_URL } from '../app-constants'

/**
 * BearerAuthorizer
 */
export const bearerAuthorizer = (data, block) => {
    if (data.token) {
        block('Authorization', `Bearer ${data.token}`);
    }
};

/**
 * CredentialsAuthenticator
 */
export const credentialsAuthenticator = createCredentialsAuthenticator({
    endpoint: `${REACT_APP_GATEWAY_SERVER_URL}/v1/login`,
    contentType: 'application/json',
    method: 'POST',
    transformRequest: ({ username, password, remember_me = false }) => {
        const userCredentials = {
            username,
            password,
            remember_me,
        };

        return JSON.stringify(userCredentials);
    },
    transformResponse: data => {
        return data
    },
    restore: (data) => {
        return new Promise((resolve, reject) => {
            if (!has(data, 'token')) {
                return reject(new Error('Missing token'));
            }

            return resolve(data)
        });
    },
    invalidate: (data) => {
        let headers = {};
        bearerAuthorizer(data, (name, value) => {
            headers[name] = value;
        });

        // Clear the Sentry user context
        Sentry.configureScope(scope => {
            scope.clear();
        });

        return fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/logout`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        }).then(response => {
            return response;
        }).catch(error => {
            return null
        })
    }
});

/**
 * Handles storage of the authenticated session
 */
export const authenticatorStorage = {
    persist(data) {
        let storage
        const shouldRemember = getValue(data, 'authenticated.metadata.remember_me', false)

        if (shouldRemember) {
            storage = localStorage
        } else {
            storage = sessionStorage
        }
        
        storage.setItem('auth', JSON.stringify(data))
        localStorage.setItem('auth', JSON.stringify(data))
        sessionStorage.setItem('auth', JSON.stringify(data)) 
    },
    restore() {
        const data = [localStorage, sessionStorage]
            .map(storage => storage.getItem('auth'))
            .filter(Boolean)[0]
        console.log('authenticatorStorage.restore() data: ', data)
        if (!data) {
            return {}
        }
        
        return JSON.parse(data)
    }    
}

/**
 * Routine, which manages the storage of the session data
 */
export const authStore = createLocalStorageStore({
    key: 'auth',
});
