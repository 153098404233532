import './SideNavigation.scss'

import * as locales from 'moment/min/locales' // eslint-disable-line

import {Connector, connect} from 'react-redux'
import React, {Component} from 'react'

import {Button} from '../components/common/button'
import {IconSettings} from '../common/icons'
import {ImpactReport} from '../common/icons'
import {MyProfile} from '../common/icons'
import ProfilePicture from '../components/ProfilePicture'
import WithProfileType from '../common/components/withProfileType'
import {getIsAuthenticated} from 'redux-simple-auth'
import {getSessionData} from '../modules/session/selectors'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
import {
  SideNavigationContainer,
  SideNavigationHeader,
  SideNavigationProfile,
  ProfileCallout,
  ProfileName,
  SideNavigationSlogan,
  SideNavigationMenu,
  SideNavigationCallout,
  SideNavigationMenuButton,
} from './SideNavigation'

class HeadquarterSideNavigation extends Component {
  onContactClick = event => {
    event.preventDefault()
    window.open('mailto:info@planzheroes.org?subject=Feedback')
  }

  onAvatarClick = event => {
    const {history, isAuthenticated} = this.props
    if (isAuthenticated) {
      history.push('/my/profile')
    }
  }

  props: Props

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.setApplicationLocale()
    }
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.setApplicationLocale()
    }
  }

  setApplicationLocale() {
    // Forcefully set the moment locale to gB
    moment.locale('en-gb')
  }

  render() {
    const {session} = this.props
    const primaryProfileAddress = session.profile && session.profile.addresses && session.profile.addresses[0]
    const profileName = primaryProfileAddress && primaryProfileAddress.firstName
    const profileImageUrl = session.profile && session.profile.image && session.profile.image.url
    const branchProfiles = session.profile && session.profile.branchProfiles
    return (
      <SideNavigationContainer className="sidenavigation">
        <SideNavigationHeader>
          <SideNavigationProfile>
            <ProfilePicture
              style={{borderRadius: '50%'}}
              size={{width: 120}}
              mode="small"
              imageUrl={profileImageUrl}
              onClick={this.onAvatarClick}
            />
            <ProfileCallout>
              Hi, <ProfileName>{profileName}</ProfileName>
            </ProfileCallout>
          </SideNavigationProfile>
          <SideNavigationSlogan>
            <WithProfileType type="business">
              <p>Thanks for donating your surplus food!</p>
            </WithProfileType>
            <WithProfileType type={['charity', 'volunteer', 'transporter']}>
              <p>Thanks for using surplus food!</p>
            </WithProfileType>
            <p>You're our hero.</p>
          </SideNavigationSlogan>
        </SideNavigationHeader>
        <SideNavigationMenu>
          <SideNavigationMenuButton to="/branches/overview" icon={ImpactReport}>
            Impact report
          </SideNavigationMenuButton>
          {branchProfiles &&
            branchProfiles.map(profile => (
              <SideNavigationMenuButton key={profile.id} to={`/branches/${profile.id}`} icon={MyProfile}>
                <React.Fragment>
                  <span className="profile__name">{profile.fullName}</span>
                  {` `}
                  <span className="profile__id">({profile.id})</span>
                </React.Fragment>
              </SideNavigationMenuButton>
            ))}
          <SideNavigationMenuButton to="/my/profile" icon={MyProfile}>
            My Profile
          </SideNavigationMenuButton>
          <SideNavigationMenuButton to="/my/settings" icon={IconSettings}>
            Settings
          </SideNavigationMenuButton>
        </SideNavigationMenu>
        <SideNavigationCallout>
          <Button className="is-orange" onClick={this.onContactClick}>
            Need help? Contact us
          </Button>
        </SideNavigationCallout>
      </SideNavigationContainer>
    )
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
  session: getSessionData(state),
  isAuthenticated: getIsAuthenticated(state),
  ...ownProps,
})

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({})

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {pure: false},
)

export default withRouter(connector(HeadquarterSideNavigation))
