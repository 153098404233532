/* Interface colours */
export const mainTextColor = '#141414';
export const secondaryTextColor = '#7F7F7F';
export const hoverTextColor = 'white';
export const borderRadius = '0px';

export const buttonTextSize = '20px';

export const primaryBg = '#98ca3d';
export const primaryHoverBg = '#7faa31';
export const secondaryBg = '#ff654f';
export const secondaryHoverBg = '#e57161';
export const cancelBg = '#4a4a4a';
export const cancelHoverBg = '#2f2e2f';

/* Border colours */
export const primaryBorder = '#7faa31';
export const secondaryBorder = '#e57161';
export const cancelBorder = '#2f2e2f';

/* Header & Footer */
export const headerHeight = '100px';
export const logoHeight = '40px';
export const footerHeight = '127px';
export const navigationBarBg = '#5a2483';
export const blackBarBg = '#000000';

/* Branding colours */
export const businessColour = '#469944';
export const charityColour = '#FF6955';
export const transporterColour = '#E15AA4';
export const volunteerColour = '#9E5DAE';

/* Buttons */
export const buttonHeight = '41px';