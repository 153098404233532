// @flow
import React, {Children, Component} from 'react';
import {connect} from 'react-redux';
import warning from 'warning';
import {getSessionData} from "redux-simple-auth";
import { isArray } from 'lodash';

const isEmptyChildren = children => Children.count(children) === 0;

type Props = {
    children?: React.Node,
    render?: (props: any) => void,
    type?: string,
    roleType?: string,
}

type State = {
    canShow: boolean,
}

/**
 * @private Returns the profile type from the authenticated user session data
 */
export const getUserRole = (state: any) => {
    const sessionData = getSessionData(state);
    const { role = 'user' } = sessionData;
    return role;
};

class WithRole extends Component<Props, State> {

    static defaultProps = {
        type: 'all',
        $roleType: undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            canShow: false,
        }
    }

    componentDidMount() {
        this.checkRoleType(this.props);
    }

    UNSAFE_componentWillMount() {
        warning(
            !(
                this.props.render &&
                this.props.children &&
                !isEmptyChildren(this.props.children)
            ),
            'You should not use both <withRole render> and <withRole children>; <withRole children> will be ignored'
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.roleType !== prevProps.roleType || this.props.$roleType !== prevProps.$roleType) {
            this.checkRoleType(this.props);
        }
    }

    checkRoleType(props: Props) {
        const { roleType, $roleType, type } = props;
        if (type === 'all') {
            this.setState({ canShow: true });
            return
        }

        const expectedType = isArray(type) ? type : [type];
        const actualRoleType = roleType || $roleType
        const canShow = expectedType && expectedType.indexOf(actualRoleType) !== -1
        this.setState({ canShow });
    }

    render() {
        const {
            render,
            children,
            roleType,
            ...props
        } = this.props;

        const { canShow = false } = this.state;
        if (!canShow) {
            return null;
        }

        if (render) {
            return render({ roleType, ...props });
        }

        return children;
    }
}

const mapStateToProps = state => ({
    roleType: getUserRole(state),
});

export default connect(mapStateToProps)(WithRole);