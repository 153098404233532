import React, { Component } from 'react';
import styled  from 'styled-components';
import './Footer.scss';
import {footerHeight, navigationBarBg} from "../common/variables";

const FooterContainer = styled.div`
  background-color: ${navigationBarBg};
  min-height: ${footerHeight};
  color: white;
`;

class Footer extends Component {

    render() {
        return (
            <FooterContainer>
                <span>Footer</span>
            </FooterContainer>
        );
    }
}

export default Footer;
