import { keyBy } from 'lodash';
import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    byId: [],
    entities: {},
    fetchStatus: {},
    errors: {},
    statistics: {
        availablePeriods: [],
        periods: {},
        fetchStatus: {},
        errors: {},
    }
}

export default function donationsReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case actionTypes.DONATION_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };
        case actionTypes.DONATION_RECEIVED:
            const itemId = payload.id;

            return {
                ...state,
                byId: [
                    ...state.byId,
                    itemId,
                ],
                entities: {
                    ...state.entities,
                    ...{[itemId]: payload},
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                isLoading: false,
            };
        case actionTypes.DONATION_REJECTED:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'error',
                },
                errors: {
                    ...state.errors,
                    [action.meta.id]: payload,
                },
                isLoading: false,
            };            

        //
        case actionTypes.UPDATE_REVIEW_DONATION_ITEM_LOADING:
            return {
                ...state,
            };


        //
        case actionTypes.UPDATE_REVIEW_DONATION_ITEM_RECEIVED:
            return {
                ...state,
                byId: [
                    ...state.byId,
                    action.meta.id,
                ],
                entities: {
                    ...state.entities,
                    ...{[action.meta.id]: payload},
                },
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
            };
        case actionTypes.UPDATE_REVIEW_DONATION_ITEM_REJECTED:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'error',
                },
                errors: {
                    ...state.errors,
                    [action.meta.id]: payload,
                },
            };            

        //
        case actionTypes.DONATIONS_LOADING:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loading',
                },
                isLoading: true,
            };

        //
        case actionTypes.DONATIONS_RECEIVED:
            const byId = payload.map(item => item.id);
            const keyedEntities = keyBy(payload, 'id');

            return {
                ...state,
                byId: [
                    ...byId
                ],
                [action.meta.kind]: [
                    ...byId
                ],
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'loaded',
                },
                error: {
                    ...state.error,
                    [action.meta.id]: payload,
                },                     
                entities: {
                    ...state.entities,
                    ...keyedEntities,
                },
                isLoading: false,
            };
        case actionTypes.DONATIONS_REJECTED:
            return {
                ...state,
                fetchStatus: {
                    ...state.fetchStatus,
                    [action.meta.id]: 'error',
                },                
                isLoading: false,
            };

        //
        case actionTypes.DONATION_PERIODS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.DONATION_PERIODS_RECEIVED:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    availablePeriods: payload,
                },
                isLoading: false,
            };
        case actionTypes.DONATION_PERIODS_REJECTED:
            return {
                ...state,
                isLoading: false,
            };

        //
        case actionTypes.STATISTICS_LOADING:
            return {
                ...state,
                isLoading: true,
                statistics: {
                    ...state.statistics,
                    fetchStatus: {
                        ...state.statistics.fetchStatus,
                        [action.meta.period]: 'loading',
                    },    
                }
            };
        case actionTypes.STATISTICS_RECEIVED:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetchStatus: {
                        ...state.statistics.fetchStatus,
                        [action.meta.period]: 'loaded',
                    },    
                    periods: {
                        ...state.statistics.periods,
                        [action.meta.period]: payload,
                    }
                },
                isLoading: false,
            };
        case actionTypes.STATISTICS_REJECTED:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    fetchStatus: {
                        ...state.statistics.fetchStatus,
                        [action.meta.period]: 'error',
                    },    
                    errors: {
                        ...state.statistics.errors,
                        [action.meta.period]: payload,
                    },                    
                },                
                isLoading: false,
            };

        default:
            return state;
    }
}