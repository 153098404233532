import * as Sentry from '@sentry/browser';
import { REACT_APP_SENTRY_URI,  REACT_APP_SENTRY_ENVIRONMENT } from './app-constants'

Sentry.init({
  dsn: REACT_APP_SENTRY_URI,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  stacktrace: true,
  tags: [
      'frontend',
  ]
})
