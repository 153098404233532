import {isArray, isNull, isObject, mapValues} from 'lodash'

export const fixNullValues = value => (isNull(value) ? '' : value)

/**
 * Map object property values
 * @param {*} obj the object to map
 * @param {*} cb  the callback method
 * @returns {*}
 */
export const mapValuesDeep = (obj, cb = fixNullValues) => {
  if (isArray(obj)) {
    return obj.map(innerObj => mapValuesDeep(innerObj, cb))
  } else if (isObject(obj)) {
    return mapValues(obj, val => mapValuesDeep(val, cb))
  } else {
    return cb(obj)
  }
}
