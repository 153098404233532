import React from 'react';
import styled from 'styled-components';
import logoBg from '../../assets/logo-white.svg';
// import { headerHeight } from '../common/variables';

const LogoDiv = styled.div`
  width: 146px;
  height: 84px;
  background-size: auto 60px;
  background-image: url(${logoBg});
  background-repeat: no-repeat;
  background-position: center;
`;

const Logo = () => (
    <React.Fragment>
        <LogoDiv />
    </React.Fragment>
);

export default Logo;
