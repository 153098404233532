import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NotificationStack } from 'react-notification';

import { Loaded } from './WebsocketProvider'

import './NotificationWatcher.scss'

class NotificationWatcher extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notifications: [    
            ],
        }
        this.isConnected = false
    }

    componentDidUpdate() {
        if (this.context.primus !== null) {
            this.prepareConnections()
        }
    }

    prepareConnections() {
        if (this.isConnected) return

        this.context.primus.on('notification', (event) => {
            if (!event.profileId) {
                console.log('Admin notification received: ', event)
            }

            this.setState({
                notifications: [
                    ...this.state.notifications,
                    {
                        title: event.title,
                        message: event.description || '',
                        key: event.notificationId,
                        dismissAfter: 5000,
                        action: event.defaultAction,
                        
                    }
                ]
            })
        })
        
        this.isConnected = true;
    }

    onNotificationClick = (event) => {
        console.log('onNotificationClick() event: ', event)
    }

    onDismissNotification = (event) => {
        console.log('onDismissNotification() event: ', event)
        this.context.primus.write({type: 'notification_read', id: event.key })

        const filteredNotifications = this.state.notifications.filter(item => item.key !== event.key)
        this.setState({
            notifications: filteredNotifications,
        })
    }

    // Improve the styling of notifications
    barStyleFactory = (index, style) => {
        return Object.assign(
            {
                'zIndex': '1000',
                'boxShadow': '0 20px 32px -8px #ccc',
            },
            style,
            { bottom: `${2 + (index * 4)}rem` }
        );
    }

    render() {
        const { notifications = [] } = this.state
        return (
            <div className="notification_watcher">
                <Loaded>
                    <div className="notification_watcher__container">
                        <div className="item green blue purple"></div>
                        <NotificationStack notifications={notifications} barStyleFactory={this.barStyleFactory} onClick={this.onNotificationClick} onDismiss={this.onDismissNotification} />
                    </div>
                </Loaded>
            </div>
        )
    }
}
NotificationWatcher.contextTypes = { primus: PropTypes.object }

export default NotificationWatcher
