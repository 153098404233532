import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Route, Switch } from "react-router-dom";

import DashboardRoute from '../../components/DashboardRoute';
import {PageRoute} from '../../components/PageRoute';
import NotFoundPage from '../../containers/NotFoundPage';
import {getProfileType} from '../../common/components/withProfileType'

import asyncComponent from '../../components/AsyncComponent';
const AsyncHomePage = asyncComponent(() => import("../HomePage"));
const AsyncHelpPage = asyncComponent(() => import("../HelpPage"));
const AsyncLoginPage = asyncComponent(() => import("../LoginPage"));
const AsyncDashboard = asyncComponent(() => import("../Dashboard"));
const AsyncHeadquarterDashboard = asyncComponent(() => import("../Dashboard/HeadquarterDashboard"));
const AsyncBranchDashboard = asyncComponent(() => import("../Dashboard/BranchDashboard"));
const AsyncCreateDonation = asyncComponent(() => import("../CreateDonation"));
const AsyncClaimDonation = asyncComponent(() => import("../ClaimDonation"));
const AsyncModifyDonation = asyncComponent(() => import("../EditDonation"));
const AsyncReviewDonation = asyncComponent(() => import("../ReviewDonationPage"));
const AsyncDeleteDonation = asyncComponent(() => import("../DeleteDonationPage"));
const AsyncDonationDetails = asyncComponent(() => import("../DonationDetails"));
const AsyncDonationOverview = asyncComponent(() => import("../DonationsOverview"));
const AsyncClaimedDonations = asyncComponent(() => import("../ClaimedDonations"));
const AsyncCreatedDonations = asyncComponent(() => import("../CreatedDonations"));
const AsyncAvailableDonations = asyncComponent(() => import("../AvailableDonations"));
const AsyncRegistration = asyncComponent(() => import("../Registration"));
const AsyncRegistrationConfirmation = asyncComponent(() => import("../RegistrationConfirmation"));
const AsyncRequestResetPassword = asyncComponent(() => import("../RequestResetPassword"));
const AsyncResetPassword = asyncComponent(() => import("../ResetPassword"));
const AsyncEditProfileDetails = asyncComponent(() => import("../EditProfileDetails"));
const AsyncProfileDetails = asyncComponent(() => import("../ProfileDetails"));
const AsyncMyProfileDetails = asyncComponent(() => import("../MyProfileDetails"));
const AsyncMyCommunity = asyncComponent(() => import("../MyCommunityPage"));
const AsyncMySettings = asyncComponent(() => import("../MySettingsPage"));
const AsyncMap = asyncComponent(() => import("../Map"));

const routeRoleMap = [
    {
        'routeType': 'DashboardRoute',
        'path': '/',
        'component': AsyncHomePage,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'Route',
        'path': '/login',
        'component': AsyncLoginPage,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'Route',
        'path': '/reset_password',
        'component': AsyncRequestResetPassword,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'Route',
        'path': '/reset_password/confirm/:token',
        'component': AsyncResetPassword,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'Route',
        'path': '/registration',
        'component': AsyncRegistration,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'Route',
        'path': '/registration/confirm/:token',
        'component': AsyncRegistrationConfirmation,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter', 'anonymous']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/dashboard',
        'component': AsyncDashboard,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/create',
        'component': AsyncCreateDonation,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/created',
        'component': AsyncCreatedDonations,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/available',
        'component': AsyncAvailableDonations,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/:id',
        'component': AsyncDonationDetails,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/:id/claim',
        'component': AsyncClaimDonation,
        'permittedRole': ['charity', 'business']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/:id/modify',
        'component': AsyncModifyDonation,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/:id/review',
        'component': AsyncReviewDonation,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/:id/delete',
        'component': AsyncDeleteDonation,
        'permittedRole': ['business', 'charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/map',
        'component': AsyncMap,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/my/profile',
        'component': AsyncMyProfileDetails,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/my/profile/modify',
        'component': AsyncEditProfileDetails,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/my/community',
        'component': AsyncMyCommunity,
        'permittedRole': ['business', 'charity', 'volunteer']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/my/settings',
        'component': AsyncMySettings,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/profiles/:id',
        'component': AsyncProfileDetails,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'PageRoute',
        'path': '/help',
        'component': AsyncHelpPage,
        'permittedRole': ['business', 'charity', 'volunteer', 'transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/branches/overview',
        'component': AsyncHeadquarterDashboard,
        'permittedRole': ['transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/branches/:id',
        'component': AsyncBranchDashboard,
        'permittedRole': ['transporter']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations',
        'component': AsyncDonationOverview,
        'permittedRole': ['charity']
    },
    {
        'routeType': 'DashboardRoute',
        'path': '/donations/claimed',
        'component': AsyncClaimedDonations,
        'permittedRole': ['charity', 'volunteer']
    },
    
]

const routePicker = role => {
    return routeRoleMap.filter(config => config.permittedRole.indexOf(role) !== -1)
        .map(meta => {
            switch(meta.routeType) {
                case 'Route':
                    return <Route exact path={meta.path} component={meta.component}/>
                case 'DashboardRoute':
                    return <DashboardRoute exact path={meta.path} component={meta.component}/>
                case 'PageRoute':
                    return <PageRoute exact path={meta.path} component={meta.component}/>
            }
        })
}


export class AppRoutes extends Component {
    render() {
        const role = this.props.profileType ? this.props.profileType : 'anonymous';
        console.log(`Role: ${role}`)
        const routes = (
            <Switch>
                {routePicker(role)}
                <Route component={NotFoundPage} />
            </Switch>
        )
        return routes;
    }
}

const mapStateToProps = state => {
    return {
        profileType: getProfileType(state)
    }
}

export default connect(mapStateToProps)(AppRoutes);