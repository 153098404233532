import * as actionTypes from './actionTypes'
import {fetch} from "redux-simple-auth"
import { processValidationErrors } from '../../utils/formValidations'
import { handleErrors } from '../../utils/handleErrors'
import { REACT_APP_GATEWAY_SERVER_URL } from '../../app-constants'

export const initialiseDonations = (error = '') => ({
    type: actionTypes.INIT,
    payload: error,
    error: true,
});

export const createDonation = (recordData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.CREATE_DONATION_LOADING})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(recordData),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.CREATE_DONATION_RECEIVED, payload: json})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.CREATE_DONATION_REJECTED, payload: errors})
                    return reject(errors);
                });
        })
    }
};

export const reviewDonationItem = (recordId: integer, itemId: integer, reviewStatus: string, itemInfo: object) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.UPDATE_REVIEW_DONATION_ITEM_LOADING, meta: { id: recordId } })

            const recordData = {
                ...itemInfo,
                status: reviewStatus,
            }

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${recordId}/items/${itemId}/review`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(recordData),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.UPDATE_REVIEW_DONATION_ITEM_RECEIVED, payload: json, meta: { id: recordId }})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.UPDATE_REVIEW_DONATION_ITEM_REJECTED, payload: errors, meta: { id: recordId }})
                    return reject(errors);
                });
        })
    }    
}

export const acceptDeliveryRequest = (recordId: integer) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.UPDATE_DELIVERY_REQUEST_LOADING, meta: { id: recordId } })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${recordId}/deliveries/accept`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.UPDATE_DELIVERY_REQUEST_RECEIVED, payload: json, meta: { id: recordId }})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.UPDATE_DELIVERY_REQUEST_REJECTED, payload: errors, meta: { id: recordId }})
                    return reject(errors);
                });
        })
    }    
}

export const updateDonationDeliveryStatus = (donationId, deliveryStatus) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.UPDATE_DONATION_DELIVERY_STATUS_LOADING})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${donationId}/deliveries/${deliveryStatus}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    }
//                    body: JSON.stringify(recordData),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.UPDATE_DONATION_DELIVERY_STATUS_RECEIVED, payload: json})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.UPDATE_DONATION_DELIVERY_STATUS_REJECTED, payload: errors})
                    return reject(errors);
                });
        })
    }
};

export const updateDonation = (recordId, recordData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.UPDATE_DONATION_LOADING, meta: { id: recordId } })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${recordId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(recordData),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.UPDATE_DONATION_RECEIVED, payload: json, meta: { id: recordId }})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.UPDATE_DONATION_REJECTED, payload: errors, meta: { id: recordId }})
                    return reject(errors);
                });
        })
    }
};

export const claimDonation = (id, data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.CLAIM_DONATION_LOADING})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${id}/claim`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.CLAIM_DONATION_RECEIVED, payload: json})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.CLAIM_DONATION_REJECTED, payload: errors})
                    return reject(errors);
                });
        })
    }
}

export const requestDelivery = (id, data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.CLAIM_DONATION_LOADING})

            const { message, ...donationRules } = data
            const deliveryRequestData = {
                message,
                ...donationRules,
            }
            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${id}/deliveries/request`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(deliveryRequestData),
                })
            ).then(processValidationErrors)
                .then(json => {
                    dispatch({type: actionTypes.CLAIM_DONATION_RECEIVED, payload: json})
                    return resolve(json)
                })

                .catch(errors => {
                    dispatch({type: actionTypes.CLAIM_DONATION_REJECTED, payload: errors})
                    return reject(errors);
                });
        })
    }
}

/**
 *
 * @param selectedImage
 * @returns {function(*=, *): Promise<any>}
 */
export const uploadDonationImage = (selectedImage) => {
    const id = '$current';
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.DONATION_IMAGE_LOADING, meta: {id}})

            // setup the form data
            const formData = new FormData();
            formData.append("image", selectedImage);

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/images`, {
                    method: 'POST',
                    body: formData,
                })
            ).then(processValidationErrors)
                .then(json => {
                    const imagePayload = {
                        type: json.type,
                        name: json.name,
                        filename: json.filename,
                        url: json.url,
                    }
                    dispatch({type: actionTypes.DONATION_IMAGE_RECEIVED, payload: imagePayload})
                    return resolve(json)
                })
                .catch(error => {
                    dispatch({type: actionTypes.DONATION_IMAGE_REJECTED, payload: error.message})
                    return reject(error)
                });
        })
    }
}

export const fetchDonations = (kind = 'recent') => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.DONATIONS_LOADING, meta: {kind}})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${kind}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        ).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.DONATIONS_RECEIVED, payload: json, meta: {kind}})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.DONATIONS_REJECTED, payload: error.message, meta: {kind}})
            });
    }
}

export const fetchDonation = (id) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.DONATION_LOADING, meta: {id}})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        ).then(handleErrors).then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.DONATION_RECEIVED, payload: json, meta: {id}})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.DONATION_REJECTED, payload: error.message, meta: {id}})
            });
    }
}

export const deleteDonation = (id) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.DELETE_DONATION_LOADING, meta: {id}})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
            ).then(handleErrors).then(res => res.json())
                .then(json => {
                    dispatch({type: actionTypes.DELETE_DONATION_RECEIVED, payload: json, meta: {id}})
                    resolve(json)
                    return json;
                })
                .catch(error => {
                    dispatch({type: actionTypes.DELETE_DONATION_REJECTED, payload: error.message, meta: {id}})
                    reject(error)
                });
        })
    }
}

/**
 * Returns the available periods for the authenticated profile
 * @returns {Function}
 */
export const getAvailableStatisticsPeriods = () => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.DONATION_PERIODS_LOADING})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/statistics/byperiod`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        ).then(handleErrors).then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.DONATION_PERIODS_RECEIVED, payload: json})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.DONATION_PERIODS_REJECTED, payload: error.message})
            });
    }
}

/**
 * Returns the statistic data for the given period
 * @param period the period to get the data for
 * @returns {Function}
 */
export const getStatisticsOfPeriod = (periodInterval) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }

    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()
    const period = `${startOfPeriod}-${endOfPeriod}`

    return (dispatch, getState) => {
        dispatch({type: actionTypes.STATISTICS_LOADING, meta: { period }})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/statistics?start=${startOfPeriod}&end=${endOfPeriod}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
        ).then(handleErrors).then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.STATISTICS_RECEIVED, payload: json, meta: { period }})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.STATISTICS_REJECTED, payload: error.message, meta: { period }})
            });
    }
}

/**
 * Export the donation report for the given period
 * @param {*} period the start of the period (monthly)
 */
export const exportDonationReport = (periodInterval) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }
    
    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_LOADING })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/reports/donations?start=${startOfPeriod}&end=${endOfPeriod}`, {
                    method: 'GET',
                })
            ).then(handleErrors).then(res => res.blob())
                .then(contents => {
                    dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_RECEIVED, payload: contents })
                    return resolve(contents);
                })
                .catch(error => {
                    dispatch({type: actionTypes.EXPORT_DONATION_REPORT_REJECTED, payload: error.message})
                    reject(error)
                });
        })
    }    
}

/**
 * Export the donation report for the given period
 * @param {*} period the start of the period (monthly)
 */
export const exportDonationReportForProfile = (periodInterval, profileId) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }
    if (!profileId) {
        throw new Error('No valid profile identifier given.')
    }
    
    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_LOADING })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/reports/donations/branch/${profileId}?start=${startOfPeriod}&end=${endOfPeriod}`, {
                    method: 'GET',
                })
            ).then(handleErrors).then(res => res.blob())
                .then(contents => {
                    dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_RECEIVED, payload: contents })
                    return resolve(contents);
                })
                .catch(error => {
                    dispatch({type: actionTypes.EXPORT_DONATION_REPORT_REJECTED, payload: error.message})
                    reject(error)
                });
        })
    }    
}

/**
 * Export the summary of all branch profiles
 * @param {*} period the start of the period (monthly)
 */
export const exportSummaryDonationReport = (periodInterval) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }
    
    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.EXPORT_SUMMARY_DONATION_REPORT_LOADING })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/reports/donations/summary?start=${startOfPeriod}&end=${endOfPeriod}`, {
                    method: 'GET',
                })
            ).then(handleErrors).then(res => res.blob())
                .then(contents => {
                    dispatch({ type: actionTypes.EXPORT_SUMMARY_DONATION_REPORT_RECEIVED, payload: contents })
                    return resolve(contents);
                })
                .catch(error => {
                    dispatch({type: actionTypes.EXPORT_SUMMARY_DONATION_REPORT_REJECTED, payload: error.message})
                    reject(error)
                });
        })
    }    
}

/**
 * Export the donation certificate for the given period
 * @param {*} period the start of the period (monthly)
 */
export const exportDonationCertificate = (periodInterval) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }
    
    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_LOADING })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/reports/certificate?start=${startOfPeriod}&end=${endOfPeriod}`, {
                    method: 'GET',
                })
            ).then(handleErrors).then(res => res.blob())
                .then(contents => {
                    dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_RECEIVED, payload: contents })
                    return resolve(contents);
                })
                .catch(error => {
                    dispatch({type: actionTypes.EXPORT_DONATION_REPORT_REJECTED, payload: error.message})
                    reject(error)
                });
        })
    }    
}

/**
 * Export the donation certificate for the given period
 * @param {*} period the start of the period (monthly)
 */
export const exportDonationCertificateForProfile = (periodInterval, profileId) => {
    if (!periodInterval) {
        throw new Error('No valid period interval given.')
    }
    if (!profileId) {
        throw new Error('No valid profile identifier given.')
    }

    const startOfPeriod = periodInterval.start.toSQLDate()
    const endOfPeriod = periodInterval.end.toSQLDate()

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_LOADING })

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/reports/certificate/branch/${profileId}?start=${startOfPeriod}&end=${endOfPeriod}`, {
                    method: 'GET',
                })
            ).then(handleErrors).then(res => res.blob())
                .then(contents => {
                    dispatch({ type: actionTypes.EXPORT_DONATION_REPORT_RECEIVED, payload: contents })
                    return resolve(contents);
                })
                .catch(error => {
                    dispatch({type: actionTypes.EXPORT_DONATION_REPORT_REJECTED, payload: error.message})
                    reject(error)
                });
        })
    }    
}
