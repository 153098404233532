export const GET_PROFILE_SETTINGS_LOADING = 'settings/GET_PROFILE_SETTINGS_LOADING';
export const GET_PROFILE_SETTINGS_RECEIVED = 'settings/GET_PROFILE_SETTINGS_RECEIVED';
export const GET_PROFILE_SETTINGS_REJECTED = 'settings/GET_PROFILE_SETTINGS_REJECTED';

export const REQUEST_PROFILE_DELETE_LOADING = 'settings/REQUEST_PROFILE_DELETE_LOADING';
export const REQUEST_PROFILE_DELETE_RECEIVED = 'settings/REQUEST_PROFILE_DELETE_RECEIVED';
export const REQUEST_PROFILE_DELETE_REJECTED = 'settings/REQUEST_PROFILE_DELETE_REJECTED';

export const UPDATE_PROFILE_SETTINGS_LOADING = 'settings/UPDATE_PROFILE_SETTINGS_LOADING';
export const UPDATE_PROFILE_SETTINGS_RECEIVED = 'settings/UPDATE_PROFILE_SETTINGS_RECEIVED';
export const UPDATE_PROFILE_SETTINGS_REJECTED = 'settings/UPDATE_PROFILE_SETTINGS_REJECTED';
