import {API_SERVER_URL} from '../../app-constants'

export const API_HOSTNAME = API_SERVER_URL || ''

export const UNCONFIRMED_USERS_LOADING = 'users/UNCONFIRMED_USERS_LOADING'
export const UNCONFIRMED_USERS_RECEIVED = 'users/UNCONFIRMED_USERS_RECEIVED'
export const UNCONFIRMED_USERS_REJECTED = 'users/UNCONFIRMED_USERS_REJECTED'

export const ACTIVE_USERS_LOADING = 'users/ACTIVE_USERS_LOADING'
export const ACTIVE_USERS_RECEIVED = 'users/ACTIVE_USERS_RECEIVED'
export const ACTIVE_USERS_REJECTED = 'users/ACTIVE_USERS_REJECTED'

export const RESEND_ACTIVATION_LOADING = 'users/RESEND_ACTIVATION_LOADING'
export const RESEND_ACTIVATION_RECEIVED = 'users/RESEND_ACTIVATION_RECEIVED'
export const RESEND_ACTIVATION_REJECTED = 'users/RESEND_ACTIVATION_REJECTED'

export const DEACTIVATE_USER_LOADING = 'users/DEACTIVATE_USER_LOADING'
export const DEACTIVATE_USER_RECEIVED = 'users/DEACTIVATE_USER_RECEIVED'
export const DEACTIVATE_USER_REJECTED = 'users/DEACTIVATE_USER_REJECTED'
