// @flow
import { Button as NewButton } from './NewButton';
//import { Button as OldButtonStyle } from './Button';

export const PrimaryButton = NewButton
export const Button = NewButton
export const OldButton = NewButton

export default { 
    PrimaryButton,
    Button,
    OldButton,
};
