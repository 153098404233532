import * as actionTypes from './actionTypes';
import { fetch } from "redux-simple-auth";
import { processValidationErrors } from '../../utils/formValidations'
import { handleErrors } from '../../utils/handleErrors'
import * as sessionActions from '../session/actions';
import { getSesssionProfile } from "../../common/selectors/profiles";
import { REACT_APP_GATEWAY_SERVER_URL } from '../../app-constants'

export const initialiseProfileEdit = (error = '') => ({
    type: actionTypes.INIT,
    payload: error,
    meta: { id: '$current' },
});

export const createProfile = (data, meta) => {
    const id = '$create';
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.CREATE_PROFILE_LOADING, meta: {...meta, id}})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                })
            ).then(processValidationErrors)
                .then(json => {
                    const profileId = json.id;
                    dispatch({type: actionTypes.CREATE_PROFILE_RECEIVED, payload: json, meta: {...meta, id}})
                    dispatch({type: actionTypes.PROFILE_RECEIVED, payload: json, meta: {...meta, id: profileId}})
                    return resolve(json);
                })
                .catch(error => {
                    dispatch({type: actionTypes.CREATE_PROFILE_REJECTED, payload: error.message, meta: {...meta, id}})
                    return reject(error);
                });
        })
    }
};

export const getProfileById = (id, isSelected) => {
    return (dispatch, getState) => {
        dispatch({type: actionTypes.PROFILE_LOADING, meta: {id, isSelected}})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        ).then(handleErrors).then(res => res.json())
          .then(json => {
                dispatch({type: actionTypes.PROFILE_RECEIVED, payload: json, meta: {id}})
                return json;
          })
          .catch(error => {
              dispatch({type: actionTypes.PROFILE_REJECTED, payload: error.message, meta: {id}})
          });
    }
};

export const getCurrentProfile = () => {
    const id = '$current';
    return (dispatch, getState) => {
        dispatch({type: actionTypes.CURRENT_PROFILE_LOADING, meta: {id}})

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/current`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        ).then(handleErrors).then(res => res.json())
            .then(json => {
                const profileId = json.id;
                dispatch({type: actionTypes.CURRENT_PROFILE_RECEIVED, payload: json, meta: {id}})
                dispatch({type: actionTypes.PROFILE_RECEIVED, payload: json, meta: {id: profileId}})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.CURRENT_PROFILE_REJECTED, payload: error.message, meta: {id}})
            });
    }
};

export const updateCurrentProfile = (data) => {
    const id = '$current';
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({type: actionTypes.UPDATE_PROFILE_LOADING, meta: {id}})

            dispatch(
                fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/current`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                })
            ).then(handleErrors).then(res => res.json())
                .then(json => {
                    const profileId = json.id;
                    dispatch({type: actionTypes.UPDATE_PROFILE_RECEIVED, payload: json, meta: {id}})
                    dispatch({type: actionTypes.PROFILE_RECEIVED, payload: json, meta: {id: profileId}})

                    // TODO: update the current user data
                    dispatch(sessionActions.updateCurrentUser(json))

                    return resolve(json);
                })
                .catch(error => {
                    dispatch({type: actionTypes.UPDATE_PROFILE_REJECTED, payload: error.message, meta: {id}})
                    return reject(error);
                });
        })
    }
};

export const updateCurrentProfileImage = (profileImage) => {
    const id = '$current';
    return (dispatch, getState) => {
        dispatch({type: actionTypes.PROFILE_IMAGE_LOADING, meta: {id}})

        // setup the form data
        const formData = new FormData();
        formData.append("image", profileImage);

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/current/image`, {
                method: 'PUT',
                body: formData,
            })
        ).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                const imagePayload = {
                    type: json.type,
                    name: json.name,
                    filename: json.filename,
                    url: json.url,
                }

                dispatch({type: actionTypes.PROFILE_IMAGE_RECEIVED, payload: imagePayload, meta: {id: '$current'}})
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.PROFILE_IMAGE_REJECTED, payload: error.message, meta: {id: '$current'}})
            });
    }
};

export const addFriendship = (id) => {

    return (dispatch, getState) => {
        dispatch({ type: actionTypes.ADD_FRIENDSHIP_LOADING, meta: { friendId: id } })

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/friends/${id}`, {
                method: 'POST',
            })
        ).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.ADD_FRIENDSHIP_RECEIVED, payload: json, meta: { friendId: id } })

                const userSessionData = getSesssionProfile(getState())
                dispatch(sessionActions.updateCurrentUser({
                    ...userSessionData,
                    friends: [
                        ...(userSessionData.friends || []),
                        { id: id, name: '' },
                    ]
                }))
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.ADD_FRIENDSHIP_REJECTED, payload: error.message, meta: { friendId: id } })
            });
    }
};

export const removeFriendship = (id) => {

    return (dispatch, getState) => {
        dispatch({ type: actionTypes.REMOVE_FRIENDSHIP_LOADING, meta: { friendId: id } })

        dispatch(
            fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/profiles/friends/${id}`, {
                method: 'DELETE',
            })
        ).then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({type: actionTypes.REMOVE_FRIENDSHIP_RECEIVED, payload: json, meta: { friendId: id } })

                const userSessionData = getSesssionProfile(getState())
                const filteredFriendIds = (userSessionData.friends || []).filter(item => item.id !== id)
                dispatch(sessionActions.updateCurrentUser({
                    ...userSessionData,
                    friends: [
                        ...filteredFriendIds,
                    ]
                }))
                return json;
            })
            .catch(error => {
                dispatch({type: actionTypes.REMOVE_FRIENDSHIP_REJECTED, payload: error.message, meta: { friendId: id } })
            });
    }
};
