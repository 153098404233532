import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import App from './containers/App'
import configureStore from './configureStore'

import './index.scss'
import GlobalStyles from './global-styles'
import './global-error-monitoring'

// eslint-disable-next-line
import registerServiceWorker from './registerServiceWorker'

const reduxStore = configureStore

ReactDOM.render(
  <Provider store={reduxStore}>
      <React.Fragment>
        <App />
        <GlobalStyles />
      </React.Fragment>
  </Provider>,
  document.getElementById('app'),
)
//registerServiceWorker();
