import { combineReducers } from 'redux'
import { reducer as session } from 'redux-simple-auth'

import donations from '../modules/donations';
import profiles from '../modules/profiles';
import lookups from '../modules/lookups';
import settings from '../modules/settings';
import current from '../modules/session';

import * as reducers from '../common/reducers'
import {resourceReducer} from 'redux-resource'
import * as helpers from '../common/reducers/helpers'
import { includedResources, httpStatusCodes } from 'redux-resource-plugins';
const {NAME: RESOURCES_NAME} = reducers.resources

const initialState = {pagination: {
    currentPage: null,
    availablePages: [],
}, requests: {}}

export default combineReducers({
    [settings.NAME]: settings.reducer,
    [lookups.NAME]: lookups.reducer,
    [donations.NAME]: donations.reducer,
    [profiles.NAME]: profiles.reducer,
    [current.NAME]: current.reducer,
    session,
    [RESOURCES_NAME]: combineReducers({
        profiles: resourceReducer('profiles', {
            plugins: [includedResources, httpStatusCodes, helpers.updateMergeMetadataResourceReducer, helpers.paginationResourceReducer],
            // plugins: [PaginationReducer, RequestsReducer],
            // processors: [CacheMetadataReducer],
            initialState,
        }),
        donations: resourceReducer('donations', {
            plugins: [includedResources, httpStatusCodes, helpers.updateMergeMetadataResourceReducer, helpers.paginationResourceReducer],
            // plugins: [PaginationReducer, RequestsReducer],
            initialState,
        }),
        users: resourceReducer('users', {
            plugins: [includedResources, httpStatusCodes, helpers.updateMergeMetadataResourceReducer, helpers.paginationResourceReducer],
            // plugins: [PaginationReducer, RequestsReducer],
            initialState,
        }),
        statistics: resourceReducer('statistics', {
            plugins: [includedResources, httpStatusCodes, helpers.updateMergeMetadataResourceReducer, helpers.paginationResourceReducer],
            // plugins: [PaginationReducer, RequestsReducer],
            initialState,
        }),
        branches: resourceReducer('branches', {
            plugins: [includedResources, httpStatusCodes, helpers.updateMergeMetadataResourceReducer, helpers.paginationResourceReducer],
            // plugins: [PaginationReducer, RequestsReducer],
            initialState,
        }),
    }),
})
