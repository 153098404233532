import React from 'react';
import styled  from 'styled-components';
import SideNavigation from '../components/SideNavigation';

const DashboardContainer = styled.div`
  display: flex;
  padding: 0px;
`;

const DashboardAside = styled.div`
    min-width: 300px;
    width: 300px;
`;

const DashboardContent = styled.div`
  flex: 2;
  background-color: #f2f2f2;
`;

export const DashboardLayout = ({ children, ...rest }) => {
    return (
        <DashboardContainer>
            <DashboardAside>
                <SideNavigation />
            </DashboardAside>
            <DashboardContent>
                {children}
            </DashboardContent>
        </DashboardContainer>
    );
};
