export const INIT = 'lookups/INIT';

export const LOOKUP_RECEIVED = 'lookups/LOOKUP_RECEIVED';
export const LOOKUP_LOADING = 'lookups/LOOKUP_LOADING';
export const LOOKUP_REJECTED = 'lookups/LOOKUP_REJECTED';

export const LOOKUP_FOODTYPES = 'lookups/LOOKUP_FOODTYPES';
export const LOOKUP_FOODTYPES_LOADING = 'lookups/LOOKUP_FOODTYPES_LOADING';
export const LOOKUP_FOODTYPES_REJECTED = 'lookups/LOOKUP_FOODTYPES_REJECTED';

export const LOOKUP_COLLECTIONTIMES = 'lookups/LOOKUP_COLLECTIONTIMES';
export const LOOKUP_COLLECTIONTIMES_LOADING = 'lookups/LOOKUP_COLLECTIONTIMES_LOADING';
export const LOOKUP_COLLECTIONTIMES_REJECTED = 'lookups/LOOKUP_COLLECTIONTIMES_REJECTED';

export const LOOKUP_DONATIONSIZES = 'lookups/LOOKUP_DONATIONSIZES';
export const LOOKUP_DONATIONSIZES_LOADING = 'lookups/LOOKUP_DONATIONSIZES_LOADING';
export const LOOKUP_DONATIONSIZES_REJECTED = 'lookups/LOOKUP_DONATIONSIZES_REJECTED';

export const LOOKUP_DONATIONFOODTYPES = 'lookups/LOOKUP_DONATIONFOODTYPES';
export const LOOKUP_DONATIONFOODTYPES_LOADING = 'lookups/LOOKUP_DONATIONFOODTYPES_LOADING';
export const LOOKUP_DONATIONFOODTYPES_REJECTED = 'lookups/LOOKUP_DONATIONFOODTYPES_REJECTED';

export const LOOKUP_TRANSPORTOPTIONS = 'lookups/LOOKUP_TRANSPORTOPTIONS';
export const LOOKUP_TRANSPORTOPTIONS_LOADING = 'lookups/LOOKUP_TRANSPORTOPTIONS_LOADING';
export const LOOKUP_TRANSPORTOPTIONS_REJECTED = 'lookups/LOOKUP_TRANSPORTOPTIONS_REJECTED';

export const LOOKUP_VOLUNTEERROLES = 'lookups/LOOKUP_VOLUNTEERROLES';
export const LOOKUP_VOLUNTEERROLES_LOADING = 'lookups/LOOKUP_VOLUNTEERROLES_LOADING';
export const LOOKUP_VOLUNTEERROLES_REJECTED = 'lookups/LOOKUP_VOLUNTEERROLES_REJECTED';

export const LOOKUP_COUNTRIES = 'lookups/LOOKUP_COUNTRIES';
export const LOOKUP_COUNTRIES_LOADING = 'lookups/LOOKUP_COUNTRIES_LOADING';
export const LOOKUP_COUNTRIES_REJECTED = 'lookups/LOOKUP_COUNTRIES_REJECTED';
