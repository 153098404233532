// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import './Input.scss';

const InputBox = styled.input`
  font-size: 12px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 5px;
  height: 40px;
  width: ${(props) => `${props.width || '100'}%`};
`;

const TitleHeader = styled.h5`
    margin-bottom: ${(props) => props.noMargin || '26px'};
    margin-top: 0;
`;

const MandatorySpan = styled.span`
    color: red;
`;

class Input extends Component<$FlowFixMeProps> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        const {
            noHeaderMargin,
            title,
            mandatory,
            placeholder,
            width,
            type,
            value,
            disabled,
            valid,
        } = this.props;
        return (
            <div>
                <TitleHeader noMargin={noHeaderMargin}>{title}{mandatory ?
                    <MandatorySpan>*</MandatorySpan> :
                    ''}
                </TitleHeader>
                <InputBox
                    placeholder={placeholder}
                    onChange={this.onChange}
                    width={width}
                    type={type || 'text'}
                    min="0"
                    required={mandatory}
                    value={value}
                    disabled={disabled}
                    valid={valid}
                    data-qa-id={this.props['data-qa-id']}
                />
            </div>
        );
    }
}

Input.propTypes = {
    onChange: PropTypes.func,
    noHeaderMargin: PropTypes.bool,
    title: PropTypes.string,
    mandatory: PropTypes.bool,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    valid: PropTypes.bool,
};

export default Input;
