import * as actionTypes from "./actionTypes";
import { fetch } from "redux-simple-auth";
import { processValidationErrors } from "../../utils/formValidations";
import { handleErrors } from "../../utils/handleErrors";
import { REACT_APP_GATEWAY_SERVER_URL } from '../../app-constants'

/**
 * Update the profile settings of the authenticated user
 * @param {*} data
 * @param {*} meta
 */
export const updateProfileSettings = (data, meta) => {
    const id = "$create";
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: actionTypes.UPDATE_PROFILE_SETTINGS_LOADING,
                meta: { ...meta, id }
            });

            dispatch(
                fetch(
                    `${
                        REACT_APP_GATEWAY_SERVER_URL
                    }/v1/profiles/settings`,
                    {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    }
                )
            )
                .then(processValidationErrors)
                .then(json => {
                    dispatch({
                        type: actionTypes.UPDATE_PROFILE_SETTINGS_RECEIVED,
                        payload: json,
                        meta: { ...meta, id }
                    });
                    return resolve(json);
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.UPDATE_PROFILE_SETTINGS_REJECTED,
                        payload: error.message,
                        meta: { ...meta, id }
                    });
                    return reject(error);
                });
        });
    };
};

/**
 * Request the deletion of the authenticated profile
 * @param {*} id the identifier of the profile
 * @param {*} data the feedback data
 */
export const requestDeleteProfile = (id, data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch({ type: actionTypes.REQUEST_PROFILE_DELETE_LOADING });

            dispatch(
                fetch(
                    `${
                        REACT_APP_GATEWAY_SERVER_URL
                    }/v1/profiles/request_delete`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                    }
                )
            )
                .then(processValidationErrors)
                .then(json => {
                    dispatch({
                        type: actionTypes.REQUEST_PROFILE_DELETE_RECEIVED,
                        payload: json
                    });
                    return resolve(json);
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.REQUEST_PROFILE_DELETE_REJECTED,
                        payload: error.message
                    });
                    return reject(error);
                });
        });
    };
};

/**
 *
 * @param {*} id
 * @param {*} isSelected
 */
export const getProfileSettings = (id, isSelected) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.GET_PROFILE_SETTINGS_LOADING,
            meta: { id, isSelected }
        });

        dispatch(
            fetch(
                `${
                    REACT_APP_GATEWAY_SERVER_URL
                }/v1/profiles/settings`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            )
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch({
                    type: actionTypes.GET_PROFILE_SETTINGS_RECEIVED,
                    payload: json,
                    meta: { id }
                });
                return json;
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.GET_PROFILE_SETTINGS_REJECTED,
                    payload: error.message,
                    meta: { id }
                });
            });
    };
};
