export const handleErrors = async (response) => {
    if (!response.ok) {
        // check if the token is expired or not
        console.warn('[fetch error] ', response.statusText)
        const payload = await response.json()
        let errorMessage = response.statusText
        if (payload.message) {
            errorMessage = payload.error
        }

        throw Error(errorMessage);
    }

    return response;
};

export default handleErrors
