import React, {Component} from 'react';
import './NotFoundPage.scss';

export class NotFoundPage extends Component {
    render() {
        return (
            <div>
                <h1>Page Not Found</h1>
                <p>
                    Couldn't find the page you requested!
                </p>
            </div>
        );
    }
}

export default NotFoundPage;
