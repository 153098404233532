// @flow
// Let's start with the polyfills!
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getIsRestored} from 'redux-simple-auth'
import Helmet, { HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';

import {history} from '../../configureStore';

import {Router} from "react-router-dom";

// preloading
import lookupActions from '../../modules/lookups/actions';
import lookupSelectors from '../../modules/lookups/selectors';

import AppRoutes from './AppRoutes';
import {PageWrapper, Wrapper, WrapperContent} from '../../components/common';
import Header from '../../components/header';
import Footer from '../../components/footer';
import LoadingIndicator from '../../common/components/LoadingIndicator';
import WebsocketProvider from '../../components/WebsocketProvider'
import NotificationWatcher from '../../components/NotificationWatcher'
import ProfileSessionDataLoader from '../../components/profiles/ProfileSessionDataLoader'
import ErrorBoundary from '../../components/ErrorBoundary'

import CacheBuster from '../../common/components/CacheBuster';

import { REACT_APP_GATEWAY_SERVER_URL } from '../../app-constants'

// Configure the default for modal windows
import './configureModal'
import './App.scss';
import 'react-tippy/dist/tippy.css';
import '../../common/index.scss'


type Props = {
    isSessionRestored: boolean,
    isAppReady: boolean,
    showFooter: boolean,
    getLookupData: () => void
};
export class App extends Component<Props> {

    componentDidMount() {
        if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#app');
        this.props.getLookupData && this.props.getLookupData();
    }


    render() {
        const { isAppReady, showFooter } = this.props;
        const rootApplication = (
            <Router history={history}>
                <HelmetProvider>
                <ProfileSessionDataLoader />
                <PageWrapper>
                    <Helmet titleTemplate="Plan Zheroes — %s">
                        <html lang="en" />
                    </Helmet>
                    {!isAppReady && (
                        <div>
                            <LoadingIndicator />
                        </div>
                    )}
                    {isAppReady && (
                        <Wrapper>
                            <WebsocketProvider url={REACT_APP_GATEWAY_SERVER_URL}>
                                <NotificationWatcher />
                            </WebsocketProvider>
                            <Header/>
                            <WrapperContent>
                                <ErrorBoundary>
                                    <AppRoutes/>
                                </ErrorBoundary>
                            </WrapperContent>
                            {showFooter && (
                                <Footer/>
                            )}
                        </Wrapper>
                    )}
                </PageWrapper>
                </HelmetProvider>
            </Router>
        );
        return (
            <CacheBuster>
                {rootApplication}
            </CacheBuster>
        )
    }
}

const mapStateToProps = state => ({
    ...state,
    isSessionRestored: getIsRestored(state),
    isLookupsReady: lookupSelectors.isReady(state),
    isAppReady: lookupSelectors.isReady(state)
});

export default connect(mapStateToProps, {...lookupActions})(App);
