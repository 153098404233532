import * as actionTypes from './actionTypes';
import { NAME } from './constants';
import { REACT_APP_GATEWAY_SERVER_URL } from '../../app-constants'

const foodTypesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_FOODTYPES,
    payload: data,
});
const donationSizesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_DONATIONSIZES,
    payload: data,
});
const donationFoodTypesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_DONATIONFOODTYPES,
    payload: data,
});
const collectionTimesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_COLLECTIONTIMES,
    payload: data,
});
const volunteerRolesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_VOLUNTEERROLES,
    payload: data,
});
const transportOptionsLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_TRANSPORTOPTIONS,
    payload: data,
});
const countriesLookupsLoaded = data => ({
    type: actionTypes.LOOKUP_COUNTRIES,
    payload: data,
});

export function getFoodTypesLookups() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/foodtypes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getCollectionTimesLookups() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/collectiontimes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getDonationSizesLookup() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donationsizes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getDonationFoodTypesLookup() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/donations/types`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getVolunteerRolesLookup() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/volunteerroles`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getTransportOptionsLookup() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/transportoptions`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getCountriesLookups() {
    return new Promise((resolve, reject) => {
        fetch(`${REACT_APP_GATEWAY_SERVER_URL}/v1/lookups/countries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error)
            })
    });
}

export function getLookupData() {
    return (dispatch, getState) => {
        const lookupState = getState()[NAME];
        if (lookupState.ready || lookupState.loading) {
            return;
        }

        dispatch({
            type: actionTypes.LOOKUP_LOADING,
        });

        const foodTypeLookup = getFoodTypesLookups()
        const collectionTimeLookup = getCollectionTimesLookups()
        const donationSizeLookup = getDonationSizesLookup()
        const donationFoodTypeLookup = getDonationFoodTypesLookup()
        const transportOptionLookup = getTransportOptionsLookup()
        const volunteerRoleLookup = getVolunteerRolesLookup()
        const countriesLookup = getCountriesLookups()

        Promise.all([foodTypeLookup, collectionTimeLookup, donationSizeLookup, donationFoodTypeLookup, volunteerRoleLookup, transportOptionLookup, countriesLookup]).then((values) => {
            dispatch(foodTypesLookupsLoaded(values[0]));
            dispatch(collectionTimesLookupsLoaded(values[1]));
            dispatch(donationSizesLookupsLoaded(values[2]));
            dispatch(donationFoodTypesLookupsLoaded(values[3]));
            dispatch(volunteerRolesLookupsLoaded(values[4]));
            dispatch(transportOptionsLookupsLoaded(values[5]));
            dispatch(countriesLookupsLoaded(values[6]));
            dispatch({
                type: actionTypes.LOOKUP_RECEIVED,
            });
        }).catch((error) => {
            dispatch({
                type: actionTypes.LOOKUP_REJECTED,
                payload: error,
                error: true,
            });
        });
    }
}

export default {
    getLookupData,
}
