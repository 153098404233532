import React from 'react';
import { PageLayout } from '../containers/PageLayout';
import { Route  } from 'react-router-dom';

export const PageRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <PageLayout>
                    <Component {...matchProps} />
                </PageLayout>
            )}
        />
    );
};