import * as actionTypes from './actionTypes';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
}

export default function settingsReducer(state = initialState, action) {
    const payload = action.payload;

    switch (action.type) {
        case actionTypes.GET_PROFILE_SETTINGS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.GET_PROFILE_SETTINGS_RECEIVED:
            return {
                ...state,
                data: payload,
                isLoading: false,
            };
        case actionTypes.GET_PROFILE_SETTINGS_REJECTED:
            return {
                ...state,
                error: payload,
                data: null,
                isLoading: false,
            };

        case actionTypes.UPDATE_PROFILE_SETTINGS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.UPDATE_PROFILE_SETTINGS_RECEIVED:
            return {
                ...state,
                error: null,
                data: payload,
                isLoading: false,
            };
        case actionTypes.UPDATE_PROFILE_SETTINGS_REJECTED:
            return {
                ...state,
                error: payload,
                data: null,
                isLoading: false,
            };

        default:
            return state;
    }
}