import { Button as BulmaButton } from 'react-bulma-components'
import '../../../common/index.scss'

export const Button = BulmaButton
export default Button

// import React, { Component } from 'react';
// import classNames from 'classnames';
// import { isFunction } from 'lodash';
// import warning from 'warning';

// import './Button.scss';


// type ButtonProps = {
//   onClick: (event: any) => void,
//   children: React.node,
//   className: string,
//   color?: string,
//   basic?: boolean,
//   compact?: boolean,
//   disabled?: boolean,
//   icon: React.node,
//   inverted?: boolean,
//   loading?: boolean,
//   negative?: boolean,
//   positive?: boolean,
//   primary?: boolean,
//   secondary?: boolean,
//   circular?: boolean,
//   role?: string,
//   size?: string,
//   tabIndex?: number,  
// }

// export class Button extends Component<ButtonProps> {

//   static defaultProps = {
//     role: 'button',
//   }  

//   buttonRef = undefined;

//   handleClick = (event) => {
//       const { disabled } = this.props

//       if (disabled) {
//         event.preventDefault()
//         return
//       }    

//       warning(this.props.onClick, 'The Button.onClick event listener is not defined. Are you sure?', this.props);

//       this.props.onClick && this.props.onClick(event);
//   }

//   render() {
//     const {
//       children,
//       className,
//       color,
//       basic,
//       compact,
//       disabled,
//       icon: IconComponent,
//       inverted,
//       loading,
//       negative,
//       positive,
//       primary,
//       secondary,
//       circular,
//       role,
//       size,
//       tabIndex,
//       type = 'button',
//       onClick,
//       ...rest
//     } = this.props

//     const baseClasses = classNames(
//         color,
//         size,
//         {
//             'compact': compact,
//             'basic': basic,
//             'circular': circular,
//             'inverted': inverted,
//             'negative': negative,
//             'positive': positive,
//             'primary': primary,
//             'secondary': secondary,
//             'is-loading': loading,
//             'has-icon': true,
//         }
//     )

//     const hasChildren = React.Children.count(children) !== 0
//     const isSimpleButton = React.Children.count(children) <= 1
//     const isIconButton = isFunction(this.props.icon)
//     const classes = classNames(baseClasses, 'button', className)
//     const isButtonType = type !== 'submit';
     
//     return (
//       <button
//         {...rest}
//         className={classes} 
//         disabled={disabled}
//         onClick={isButtonType ? this.handleClick : null}
//         ref={(node) => this.buttonRef = node}
//         role={role}
//         tabIndex={tabIndex}>
//         {isSimpleButton && isIconButton && (<i><IconComponent /></i>)}
//         {hasChildren && isIconButton && (<span>{children}</span>)}
//         {hasChildren && !isIconButton && children}
//     </button>
//     )
//   }
// }

// export default { PrimaryButton: Button, Button };
