// @flow
import styled from 'styled-components';
import { mainTextColor, secondaryTextColor } from './variables';

const Title = styled.h2`
  font-family: OpenSansRegular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  color: ${secondaryTextColor};
`;

const SubTitle = styled.h3`
  font-family: BreeSerifRegular;
  margin: 0;
  padding: 0;
  font-size: 1.5em;
  color: ${secondaryTextColor};
`;

const Paragraph = styled.p`
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  font-size: 1em;
  color: ${mainTextColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; // TODO: not sure why this is necessary
  
  @media (min-width: 1140px) {
    width: 1140px;
  }

  @media (min-width: 1240px) {
    width: 1240px;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  background-image: url("https://www.planzheroes.org/images/backgrounds/simple.png");
  height: 100%;
`;

const MainContentWrapper = styled.div`
  background: fuchsia;
`;

const WrapperContent = styled.div`
  background: white;
`;

export {
    Title,
    SubTitle,
    Paragraph,
    PageWrapper,
    Wrapper,
    WrapperContent,
    MainContentWrapper,
}