// @flow
/* global Primus */
import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import {getSessionData, getIsAuthenticated} from 'redux-simple-auth'
import { REACT_APP_GATEWAY_SERVER_URL } from '../app-constants'

type Props = {
    children: React.node,
    url: string,
    config: ?object,
    userSession: ?object
}

export class Loading extends Component {
    render() {
        if (!this.context.primus) return null  
        if (this.context.primus && this.context.primus.readyState === Primus.OPEN) return null

        return Children.only(this.props.children)
    }
}
Loading.contextTypes = { primus: PropTypes.object };

export class Loaded extends Component {
    render() {
      if (!this.context.primus) return null  
      if (this.context.primus && this.context.primus.readyState !== Primus.OPEN) return null
  
      return Children.only(this.props.children)
    }
}  
Loaded.contextTypes = { primus: PropTypes.object };

class WebsocketProvider extends Component<Props> {

    primus = null;             // Reference to the internal Primus instance.

    getChildContext() {
        return {
            primus: this.primus
        }
    }

    componentDidMount() {
        this.createScript(`${REACT_APP_GATEWAY_SERVER_URL}/primus/primus.js`, {})
    }

    onScriptLoaded() {
        if (this.props.isAuthenticated) {
            this.open();
        }
    }

    createScript(url, attributes) {
        const script = document.createElement('script');
        if (attributes) {
            Object.keys(attributes).forEach(prop => script.setAttribute(prop, attributes[prop]));
        }
        script.src = url;

        // default async to true if not set with custom attributes
        if (!script.hasAttribute('async')) {
            script.async = 1;
        }

        script.onload = () => {
            this.onScriptLoaded()
        };

        script.onerror = () => {
        };

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        this.close();
    }    

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
            if (this.props.isAuthenticated) {
                this.open()
                this.forceUpdate()
            } else {
                this.close()
                this.forceUpdate()
            }
        }
    }

    open() {
        const { url = REACT_APP_GATEWAY_SERVER_URL, config, userSession } = this.props

        if (this.primus) this.close()

        const { token } = userSession
        try {
            this.primus = new Primus(`${url}?token=${token}`, {
                ...config,
    
                manual: false,
            })    
        } catch (error) {
            console.warn('Something went wrong while initialising Primus');
            this.primus = null;
        }
    }

    close() {
        try { this.primus.destroy() }
        catch (e) {}
        this.primus = null
    }

    render() {
        return this.props.children;
    }
}
WebsocketProvider.childContextTypes = {
    primus: PropTypes.object
}

const mapStateToProps = state => ({
    userSession: getSessionData(state),
    isAuthenticated: getIsAuthenticated(state),
});

export default connect(mapStateToProps)(WebsocketProvider);
