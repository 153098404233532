import React, { Component } from 'react';
import classNames from 'classnames';
import './Image.scss';

class Image extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            hasFailed: false,
        }
    }

    componentDidMount() {
        this.imageElement.addEventListener('load', this.onImageLoaded, { once: true });

        this.imageElement.addEventListener('error', this.onImageFailed, { once: true });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({ isLoading: true });
        }
    }

    componentWillUnmount() {
        this.imageElement.removeEventListener('load', this.onImageLoaded);
        this.imageElement.removeEventListener('error', this.onImageFailed);
        this.imageElement = null
    }

    onImageFailed = () => {
        this.setState({ isLoading: false, hasFailed: true });
        this.props.onImageError && this.props.onImageError();
    }

    onImageLoaded = () => {
        this.setState({ isLoading: false });
        this.props.onImageLoaded && this.props.onImageLoaded();
    }

    imageElement = undefined;

    render() {
        const { style, className, onImageError, onImageLoaded, ...otherProps } = this.props;
        return (
            <div style={style} className={classNames(className, { 'is-loading': this.state.isLoading, 'is-error': this.state.hasFailed })}>
                <img ref={(node => this.imageElement = node)} alt="" style={{width: 'inherit%', height: 'inherit%'}} {...otherProps} />
            </div>
        )
    }
}

export default Image
