import React, { Component } from "react";
import classNames from 'classnames';
import MyProfile from '../common/icons/MyProfile'
import Image from './elements/Image';

import './ProfilePicture.scss';

type Props = {
    onClick: () => void,
    imageUrl?: string,
    title?: string,
    size?: string,
    mode?: string,
};

export class ProfilePicture extends Component<Props> {

    static defaultProps: Props = {
        mode: 'small',
    }

    constructor(props) {
        super(props)
        this.state = {
            shouldDisplayPlaceholder: !this.props.imageUrl,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageUrl !== this.props.imageUrl) {
            this.setState({
                shouldDisplayPlaceholder: !this.props.imageUrl
            })
        }
    }

    onImageError = () => {
        this.setState({shouldDisplayPlaceholder:  true})
    }

    onImageClick = (event) => {
        event.preventDefault()
        this.props.onClick && this.props.onClick()        
    }

    render() {
        const { imageUrl, size = {}, mode='small', title='', style } = this.props;
        const { shouldDisplayPlaceholder } = this.state;

        let sizeQuery = [];
        if (size.width) sizeQuery.push(`width=${size.width}`)
        if (size.height) sizeQuery.push(`width=${size.height}`)
        let sizeQueryString = sizeQuery.join('&');
        sizeQueryString = sizeQueryString.length > 0 ? '?' + sizeQueryString : '';

        const imageMode = `profileimage--${mode}`;

        return (
            <div className={classNames(`profileimage ${imageMode}`, {'profileimage-is-missing-image': shouldDisplayPlaceholder})} style={style} >
                <div className="profileimage__container">
                    {shouldDisplayPlaceholder && (
                        <MyProfile style={{fill: 'white', width: '70px', height: '100px', top: '8px', position: 'relative'}} />
                    )}
                    {!shouldDisplayPlaceholder && (
                        <React.Fragment>
                            <Image onImageError={this.onImageError} className="profileimage__image" onClick={this.onImageClick} src={`${imageUrl}${sizeQueryString}`} alt={title} />
                        </React.Fragment>
                    )}
                </div>
            </div>
        )
    }
}


export default ProfilePicture;
