import {isEmpty, isString, omit} from 'lodash'

import {getSessionData} from '../reducers/session/selectors'
import {mapValuesDeep} from '../utils/mapValuesDeep'

/**
 * Returns an existing profile into the form data structure for use in forms
 * @param profile
 * @returns Object
 */
export const getEditableProfileData = profile => {
  if (!profile) return null

  // iterate through the whole structure to replace null with an empty string (sad days)
  const editableProfileData = mapValuesDeep(profile)
  return {
    ...editableProfileData,
  }
}

/**
 * Generate a submittable version of the form data for a profile
 * @param profile
 * @returns Object
 */
export const getSubmittableProfileData = profile => {
  return mapValuesDeep(omit(profile, ['image']), value => {
    if (!isString(value)) return value
    return isEmpty(value) ? null : value
  })
}

/**
 * Returns the type of the given profile
 * @param profile
 * @returns string
 */
export const getProfileType = profile => {
  return profile.profileType || ''
}

/**
 * Returns the type of the given profile
 * @param profileType
 * @returns string
 *
 */
export const getRedirectUrlByProfileType = (profileType, isBranchProfile) => {
  // wdb: Based on email conversation on 25.06.2018 @ 15:46 -- PZ request to always redirect to the dashboard
  switch (profileType) {
    case 'business':
      return isBranchProfile ? '/donations/create' : '/branches/overview'
    case 'charity':
      return '/donations/available'
    case 'transporter':
      return '/map'
    case 'volunteer':
      return '/map'
    default:
      return '/dashboard'
  }
}

/**
 * Returns the profile in the session data
 * @param state
 * @returns object
 */
export const getSesssionProfile = state => {
  const currentSessionData = getSessionData(state)
  return currentSessionData && currentSessionData.profile
}

export default {
  getSubmittableProfileData,
  getEditableProfileData,
  getProfileType,
  getSesssionProfile,
}
